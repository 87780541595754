import styled from 'styled-components';
import { darken } from 'polished';

import Button from '~/components/Button';

export const Container = styled.div`
  margin: 24px 64px;

  .recomendation-radio {
    display: flex;
    align-items: center;
    margin: 16px;
    color: #444444;
    font-size: 14px;
    font-weight: bold;

    input {
      margin-left: 12px;
    }
  }
`;

export const FormHead = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 28px;
  margin-top: 24px;
  position: relative;

  &::before {
    content: '';
    width: 20px;
    height: 1px;
    position: absolute;
    background: #dedede88;
    margin-bottom: 16px;
    top: -8px;
  }
`;

export const RecomendationInfoForm = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    margin: 16px;
  }
`;

export const SpeckForm = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  & > div {
    margin: 16px;
    width: 100%;
  }
`;

export const Top10List = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Top10Item = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin: 16px;
  font-size: 20px;
`;

export const SubmitButton = styled(Button)`
  background: #fff;
  color: #183757;
  float: right;
  margin: 16px;
  position: fixed;
  height: 60px;
  border-radius: 40px;
  bottom: 0;
  right: 0;
  box-shadow: 1px 1px 4px 1px #bbb;
  font-weight: bold;
  font-size: 16px;
  transition: background 0.2s;

  &:hover {
    background: ${darken(0.04, '#fff')};
  }
`;
