import styled from 'styled-components';
import { darken } from 'polished';

import SectionHeadWrapper from '~/components/SectionHead';
import Button from '~/components/Button';

export const Container = styled.div`
  margin: 24px 64px;
  display: flex;
  flex-direction: column;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    z-index: -15;
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ0MCIgaGVpZ2h0PSI2MDU0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0xMjYzLjU0OCAyLjY3M0wtMTA3IDc5Mi44MDVWNzc0NWgxNjU1VjE1Ni45ODZMMTI4My42MTYgMi43MjZhMjAgMjAgMCAwIDAtMjAuMDY4LS4wNTN6IiBmaWxsPSIjRThFQUVEIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIG9wYWNpdHk9Ii43Ii8+PC9zdmc+');
  }

  .textBox {
    position: relative;
    .total-text {
      position: absolute;
      right: 20px;
      bottom: 8px;
      font-weight: bold;
    }
  }
`;

export const SectionHead = styled(SectionHeadWrapper)`
  .description {
    span {
      font-size: 18px;
      color: #fff;
      text-align: right;
    }

    p {
      font-size: 14px;
      color: #ddd;
      text-align: right;
    }
  }

  @media (max-width: 900px) {
    & {
      justify-content: center;

      .description {
        display: none;
      }
    }
  }
`;

export const TextArea = styled.textarea.attrs({
  rows: 14,
})`
  border: 1px solid #888;
  border-radius: 10px;
  width: 100%;
  margin-top: 26px;
  padding: 8px;
  font-size: 14px;
`;

export const LoadBar = styled.div`
  width: 100%;
  border-radius: 8px;
  height: 18px;
  background: #c4c4c4;
  margin-top: 4px;
  position: relative;
  overflow: hidden;

  .percent-loaded {
    width: ${props => `${props.percent}%`};
    background: #ec9514;
    border-radius: 8px;
    height: 18px;
    position: absolute;
  }
`;

export const SubmitButton = styled(Button)`
  background: #0074a9;
  color: #fff;
  float: right;
  margin: 16px;
  position: fixed;
  height: 60px;
  border-radius: 40px;
  bottom: 0;
  right: 0;
  box-shadow: 1px 1px 4px 1px #bbb;
  font-weight: bold !important;
  font-size: 16px;
  transition: all 0.4s;
  overflow: hidden;

  width: 60px;

  span {
    opacity: 0;
    transition: transform 0.4s;
    transition: opacity 0.2s;
  }

  svg {
    position: absolute;
    right: 18px;
  }

  &:hover {
    background: ${darken(0.04, '#0074a9')};

    width: 140px;

    span {
      transform: translateX(-10px);
      opacity: 1;
    }
  }
`;
