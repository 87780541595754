import styled from 'styled-components';
import AsyncSelect from 'react-select/async';
import { darken } from 'polished';
import SectionHeadWrapper from '~/components/SectionHead';

export const Container = styled.div`
  margin: 24px 64px;

  .general-info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div:nth-child(2) {
      min-width: 200px;
    }

    strong {
      font-size: 18px;
    }

    p {
      font-size: 12px;
      color: #666;
    }
  }
  .stage-Evaluation {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    border-top: 1px #e2e0e0 solid;
    margin-top: 20px;

    span {
      margin-top: 5px;
    }
  }
`;

export const SectionHead = styled(SectionHeadWrapper)`
  svg {
    cursor: pointer;
    margin-left: 26px;
  }

  & > div {
    display: flex;
    align-items: center;
  }
`;

export const AsyncInput = styled(AsyncSelect)``;

export const RecomendationContainer = styled.div`
  margin-top: 26px;
  border: 1px solid #ddd;
  border-radius: 8px;

  header {
    height: 80px;
    background: #337ab7;
    display: flex;
    justify-content: start;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-size: 16px;
    color: #fff;

    svg {
      margin-left: 16px;
    }
  }

  .recomendations {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    padding: 60px;

    .card {
      padding: 12px 16px;
      display: flex;
      flex-wrap: wrap;

      h1 {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 8px;
      }

      .buttonContainer1 {
        margin-left: 20px;
        margin-bottom: 10px;
      }

      .buttonContainer2 {
        display: flex;
        align-items: center;
      }

      .buttonContainer {
        margin-top: 10px;
        display: flex;
        flex: 1;
        justify-content: flex-end;
        button {
          width: 100px;
          background: #0074a9;
          padding: 2px;
          border-radius: 2px;
          color: white;
          font-weight: bold;
          text-decoration: none;
          &:hover {
            background: ${darken(0.06, '#0074a9')};
          }
        }
      }

      .action {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;

        .icons {
          display: flex;
          align-items: center;

          p {
            font-size: 12px;
            color: #666;
          }

          svg {
            cursor: pointer;
            margin-left: 4px;
          }
        }

        p {
          font-size: 14px;
          color: #333;
        }
      }
    }
  }

  .empty-recomendations {
    color: #666;
    margin-top: 16px;
    font-size: 16px;
  }

  .empty-actions {
    color: #666;
    margin-top: 16px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const DivPagination = styled.div`
  margin-top: 10px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  .title {
    display: flex;
    justify-content: center;
    width: 200px;

    span {
      font: 20px;
    }
  }
`;

const CardContainer = styled.div`
  margin-top: 26px;
  border: 1px solid #ddd;
  border-radius: 8px;

  header {
    height: 80px;
    background: #393780;
    display: flex;
    justify-content: start;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-size: 16px;
    color: #fff;

    svg {
      margin-left: 16px;
    }
  }

  .items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    min-height: 300px;

    .title-item {
      margin-bottom: 20px;
    }

    .item {
      position: relative;
      width: 80%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 12px;

      & > h1 {
        font-size: 18px;
        margin-bottom: 8px;
      }
    }
  }

  .item-pagination {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }
`;

export const GradeContainer = styled(CardContainer)`
  header {
    background: #3530cf;
  }
`;

export const SpeckContainer = styled(CardContainer)`
  header {
    background: #393780;
  }
`;

export const Top10Container = styled(CardContainer)`
  header {
    background: #161442;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;

  .title {
    margin-bottom: 15px;
    font-weight: 800;
    font-size: 24px;
  }

  div {
    display: flex;
    justify-items: center;
    align-items: center;
  }

  input {
    margin: 10px;
    cursor: pointer;
  }

  label {
    cursor: pointer;
  }

  button {
    position: absolute;
    bottom: 10px;
    right: 10px;

    width: 120px;
    background: #0074a9;
    padding: 8px 20px;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    text-decoration: none;

    &:hover {
      background: ${darken(0.06, '#0074a9')};
    }
  }
`;
