import styled from 'styled-components';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { darken } from 'polished';

export const FormStyle = styled.form`
  display: flex;
  flex-direction: column;

  input,
  select {
    margin-bottom: 20px;
    border: 1px solid #dddddd;
    padding: 10px 15px;
    color: #333;
    border-radius: 4px;
  }

  label {
    font-weight: bold;
    margin-bottom: 20px;
  }

  .containerDate {
    display: flex;
    justify-content: space-between;

    div {
      display: flex;
      flex-direction: column;

      input {
        width: 240px;
        ::-webkit-calendar-picker-indicator {
          cursor: pointer;
        }
      }
    }
  }

  button {
    width: 240px;
    background: #0074a9;
    padding: 10px 30px;
    border-radius: 5px;
    color: white;
    margin-bottom: 30px;
    font-weight: bold;
    text-decoration: none;
    display: inline-block;

    &:hover {
      background: ${darken(0.06, '#0074a9')};
    }
  }
`;

export const Container = styled.div`
  margin: 24px 256px;

  @media (max-width: 1000px) {
    margin: 0;
  }
`;

export const ContainerForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const SelectStyles = styled(Select)`
  width: 120px;
  margin-right: 10px;
  border-radius: 8px;
  color: #0074a9;
`;

export const SelectUnit = styled(AsyncSelect)`
  & > div {
    padding-left: 5px;
    margin-bottom: 15px;
  }
`;
