/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-expressions */

import React, { useEffect, useState } from 'react';
import { MdArrowBack } from 'react-icons/md';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import SectionHead from '~/components/SectionHead';
import Loader from '~/components/Loader';

import history from '~/services/history';
import { Container, ContainerForm, FormStyle } from './styles';
import api from '~/services/api';

export default function Form({ match }) {
  const { id } = match.params;

  const stateUnit = useSelector(state => state.auth.user.unit);

  const access_level_id = useSelector(state =>
    state.auth && state.auth.user && state.auth.user.access_level
      ? state.auth.user.access_level.id
      : null
  );

  const { register, handleSubmit } = useForm();

  const [loading, setLoading] = useState(false);
  const [unitSelect, setUnitSelect] = useState(true);
  const [semesterSelect, setSemesterSelect] = useState(true);
  const [dataStage, setDataStage] = useState();

  const [defaultUnitOptions, setDefaultUnitOptions] = useState([]);
  const [semesters, setSemesters] = useState([]);

  const currentDate = format(new Date(), 'yyyy-MM-dd');

  let userUnit = null;
  if (access_level_id === 3 || access_level_id === 4) {
    userUnit = stateUnit;
  }

  useEffect(() => {
    setLoading(true);
    if (id) {
      api.get(`/evaluation-stage/${id}`).then(response => {
        const { data } = response;

        const start_dateFormat = format(
          parseISO(data.start_date),
          'yyyy-MM-dd',
          { locale: pt }
        );
        const end_dateFormat = format(parseISO(data.end_date), 'yyyy-MM-dd');

        data.start_date = start_dateFormat;
        data.end_date = end_dateFormat;

        setDataStage(data);
        setLoading(false);
      });
    }
  }, [id]);

  useEffect(() => {
    try {
      if (!userUnit) {
        setLoading(true);
        api.get('/units').then(response => {
          setDefaultUnitOptions(response.data);
          setUnitSelect(false);
        });
      }
      setLoading(false);
    } catch (error) {
      toast.error('Falha ao carregar unidades');
      setLoading(false);
    }
  }, [userUnit]);

  useEffect(() => {
    setLoading(true);
    api.get('/semesters').then(response => {
      const { data } = response;

      setSemesterSelect(false);
      setSemesters(data);
      setLoading(false);
    });
  }, []);

  const onSubmit = async data => {
    if (id) {
      setLoading(true);
      try {
        const id_unit = data.id_unit ? data.id_unit : userUnit.id;
        const res = await api.put(`/evaluation-stage/${id}`, {
          ...data,
          id_unit,
        });
        if (res.data.error) {
          setLoading(false);
          return toast.warn(res.data.error);
        }
        toast.success('Sucesso!');
        setLoading(false);
        setTimeout(() => {
          history.push('/stages');
        }, 2000);
      } catch (error) {
        toast.error('Erro ao salvar alterações!');
        setLoading(false);
      }
    } else {
      setLoading(true);
      try {
        const id_unit = data.id_unit ? data.id_unit : userUnit.id;

        const res = await api.post('/evaluation-stage', { ...data, id_unit });
        if (res.data.error) {
          setLoading(false);
          return toast.warn(res.data.error);
        }
        toast.success('Sucesso!');
        setLoading(false);
        history.push('/stages');
      } catch (error) {
        toast.error('Erro ao salvar!');
        setLoading(false);
      }
    }
  };

  return (
    <>
      <SectionHead>
        <h1>{id ? 'Editar fase avaliativa' : 'Criar nova avaliação'}</h1>
        <MdArrowBack
          size={20}
          style={{ cursor: 'pointer' }}
          onClick={() => history.goBack()}
        />
      </SectionHead>

      <Container>
        <FormStyle onSubmit={handleSubmit(onSubmit)}>
          <ContainerForm>
            <label>Titulo:</label>
            <input
              defaultValue={dataStage ? dataStage.title : null}
              name="title"
              placeholder="Titulo da fase de avaliação"
              label="Titulo"
              ref={register}
              required
            />
            <label>Descrição:</label>
            <input
              defaultValue={dataStage ? dataStage.description : null}
              name="description"
              placeholder="Descrição da etapa de avaliação"
              label="Descrição"
              ref={register}
              required
            />
            <label hidden={unitSelect}>Unidade:</label>
            {!userUnit && (
              <select
                type="select"
                name="id_unit"
                ref={register}
                hidden={unitSelect}
                required
              >
                {dataStage ? (
                  defaultUnitOptions.map(unit => {
                    if (dataStage.unit.name === unit.name) {
                      return (
                        <option value={unit.id} selected>
                          {unit.name}
                        </option>
                      );
                    }
                    return <option value={unit.id}>{unit.name}</option>;
                  })
                ) : (
                  <>
                    <option value="" selected disabled hidden>
                      Unidade
                    </option>
                    {defaultUnitOptions.map(unit => (
                      <option value={unit.id} key={unit.id}>
                        {unit.name}
                      </option>
                    ))}
                  </>
                )}
              </select>
            )}

            <label hidden={semesterSelect}>Semestre:</label>
            {semesters.length > 0 && (
              <select
                type="select"
                name="id_semester"
                ref={register}
                hidden={semesterSelect}
                required
              >
                {dataStage ? (
                  semesters.map(semester => {
                    if (dataStage.id_semester === semester.id) {
                      return (
                        <option value={semester.id} selected>
                          {semester.description}
                        </option>
                      );
                    }
                    return (
                      <option value={semester.id}>
                        {semester.description}
                      </option>
                    );
                  })
                ) : (
                  <>
                    <option value="" selected disabled hidden>
                      Semestre
                    </option>
                    {semesters.map(semester => (
                      <option value={semester.id} key={semester.id}>
                        {semester.description}
                      </option>
                    ))}
                  </>
                )}
              </select>
            )}
          </ContainerForm>

          <div className="containerDate">
            <div>
              <label>Data Inicial:</label>
              <input
                defaultValue={dataStage ? dataStage.start_date : null}
                name="start_date"
                type="date"
                min={dataStage ? dataStage.start_date : currentDate}
                ref={register}
                required
              />
            </div>

            <div>
              <label>Data Final:</label>
              <input
                defaultValue={dataStage ? dataStage.end_date : null}
                label="Data final"
                name="end_date"
                type="date"
                min={currentDate}
                ref={register}
                required
              />
            </div>
          </div>
          <button type="submit"> Salvar </button>
        </FormStyle>
        {/* <span>
          Obs: As datas disponíveis, são referentes ao semestre atual com fim em
          <span style={{ fontWeight: 'bold', marginLeft: '2px' }}>
            {endDateSemesterPrint}
          </span>
          .
        </span> */}
        <Loader visible={loading} />
      </Container>
    </>
  );
}

Form.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

Form.defaultProps = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: null,
    }),
  }),
};
