import styled from 'styled-components';
import { darken } from 'polished';

import Button from '~/components/Button';

export const Container = styled.div``;

export const SubmitButton = styled(Button)`
  background: #0074a9;
  color: #fff;
  float: right;
  margin: 16px;
  position: fixed;
  height: 60px;
  border-radius: 40px;
  bottom: 0;
  right: 0;
  box-shadow: 1px 1px 4px 1px #bbb;
  font-weight: bold !important;
  font-size: 16px;
  transition: all 0.4s;
  overflow: hidden;

  width: 60px;

  span {
    opacity: 0;
    transition: transform 0.4s;
    transition: opacity 0.2s;
  }

  svg {
    position: absolute;
    right: 18px;
  }

  &:hover {
    background: ${darken(0.04, '#0074a9')};

    width: 140px;

    span {
      transform: translateX(-10px);
      opacity: 1;
    }
  }
`;
