import React, { useState, useEffect, useCallback } from 'react';
import { MdAdd, MdSearch, MdEdit, MdDelete } from 'react-icons/md';
import { FaUserTie } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { Container, UsersList, AddButton } from './styles';

import SectionHead from '~/components/SectionHead';
import Pagination from '~/components/Pagination';
import ConfirmDialog from '~/components/ConfirmDialog';
import Loader from '~/components/Loader';

import history from '~/services/history';
import api from '~/services/api';

export default function Users() {
  const userId = useSelector(state => state.auth.user.id);

  const [users, setUsers] = useState([]);

  const [loading, setLoading] = useState(false);

  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [filter, setFilter] = useState('');

  const loadActions = useCallback(async () => {
    try {
      setLoading(true);

      const response = await api.get('/users', {
        params: { page: currentPage, name: filter },
      });
      setPages(Number(response.headers.total_pages));
      setUsers(response.data);
    } catch (error) {
      toast.error('Erro ao listar usuários');
    } finally {
      setLoading(false);
    }
  }, [currentPage, filter]);

  useEffect(() => {
    loadActions();
  }, [loadActions]);

  async function handleDelete(user) {
    async function deleteUser() {
      try {
        if (Number(userId) === Number(user.id)) {
          setLoading(false);
          return toast.warn('Você não pode deletar seu próprio usuário');
        }

        setLoading(true);
        await api.delete(`/users/${user.id}`);
        toast.success('Usuário excluído');
      } catch (error) {
        toast.error('Erro ao deletar usuário');
      } finally {
        await loadActions();
        setLoading(false);
      }
    }

    ConfirmDialog({
      title: 'Apagar usuário',
      onConfirm: deleteUser,
      content: <p>Tem certeza que deseja apagar o usuário?</p>,
    });
  }

  return (
    <>
      <SectionHead>
        <h1>USUÁRIOS</h1>
        <div className="filter-options">
          <AddButton
            icon={<MdAdd />}
            onClick={() => history.push('/users/new')}
          >
            Adicionar usuário
          </AddButton>

          <div className="search-bar">
            <form>
              <input
                type="text"
                placeholder="nome do usuário"
                value={filter}
                onChange={e => setFilter(e.target.value)}
              />
            </form>
            <MdSearch size="20" />
          </div>
        </div>
      </SectionHead>
      <Container>
        <UsersList>
          {users.map(user => (
            <li key={user.id}>
              <FaUserTie size={24} color="#222" />
              <div className="info">
                <h1>{user.name}</h1>
                <p>Unidade: {user.unit.name}</p>
              </div>
              <div className="options">
                <button
                  type="button"
                  onClick={() => history.push(`/users/${user.id}`)}
                >
                  <MdEdit size={20} color="#4185f4" />
                </button>
                <MdDelete
                  size={20}
                  color="#ea443b"
                  onClick={() => handleDelete(user)}
                />
              </div>
            </li>
          ))}
        </UsersList>
        <Pagination
          pages={pages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Container>
      <Loader visible={loading} />
    </>
  );
}
