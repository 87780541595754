/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  Fragment,
} from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { parseISO, format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import {
  MdBookmark,
  MdPlayCircleOutline,
  MdArrowBack,
  MdFileDownload,
  MdHighlightOff,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import ReactStars from 'react-stars';

import { FaRegStopCircle } from 'react-icons/fa';

import { useForm } from 'react-hook-form';
import Modal from '~/components/Modal';
import Stars from '~/components/Stars';

import {
  Container,
  SectionHead,
  AsyncInput,
  RecomendationContainer,
  GradeContainer,
  DivPagination,
  SpeckContainer,
  Top10Container,
  Form,
} from './styles';

import Loader from '~/components/Loader';

import api from '~/services/api';
import history from '~/services/history';

export default function Info({ match }) {
  const { id } = match.params;

  const { register, handleSubmit } = useForm();

  const [perPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [yes, setYes] = useState(false);

  const [studentData, setStudentData] = useState(null);
  const [evaluations, setEvaluations] = useState([]);

  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  // const [range, setRange] = useState(0);
  const [stars, setStars] = useState(0);

  const [selectedSemester, setSelectedSemester] = useState(null);
  const [semesters, setSemesters] = useState([]);
  const [idAction, setIdAction] = useState();

  const [isTop10, setIsTop10] = useState(false);

  const indexOfLastPost = currentPage * perPage;
  const indexOfFirstPost = indexOfLastPost - perPage;
  const currentEvaluationData = evaluations.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const formatedEssayDate = useMemo(() => {
    return studentData && studentData.lastUpdatedEssay
      ? format(
          parseISO(studentData.lastUpdatedEssay),
          "dd 'de' MMMM 'de' yyyy",
          { locale: pt }
        )
      : null;
  }, [studentData]);

  const formatedTop10Date = useMemo(() => {
    return studentData && studentData.lastUpdatedTop10
      ? format(
          parseISO(studentData.lastUpdatedTop10),
          "dd 'de' MMMM 'de' yyyy",
          { locale: pt }
        )
      : null;
  }, [studentData]);

  const loadStudentInfo = useCallback(
    async id_semester => {
      try {
        setLoading(true);

        const response = await api.get(`/students/${id}`, {
          params: { id_semester },
        });

        const { data } = response;

        setStudentData(data);
        setEvaluations(data.evaluations);
        setSelectedSemester(data.semester);
        setIsTop10(data.semester.description.split('/')[1] < 2020);
      } catch (error) {
        toast.error('Erro ao carregar dados do aluno');
      } finally {
        setLoading(false);
      }
    },
    [id]
  );

  useEffect(() => {
    loadStudentInfo();
  }, [loadStudentInfo]);

  async function downloadPdf() {
    const [matchedRecomendations] = currentEvaluationData.map(item =>
      item.matchedRecomendations.map(recomendations => {
        return { actions: recomendations.actions, name: recomendations.name };
      })
    );

    const dataMatchedRecomendations = matchedRecomendations;

    const [resTop10Atributes] = currentEvaluationData.map(
      item => item.resTop10Atributes
    );
    const [resSpeck] = currentEvaluationData.map(item => item.resSpeck);

    try {
      if (studentData.grades.length === 0) {
        return toast.warn('Estudante ainda não possui notas do bimestre atual');
      }

      if (resSpeck.length === 0) {
        return toast.warn('Estudante não finalizou a redação speck');
      }

      if (resTop10Atributes.length === 0) {
        return toast.warn('Estudante não finalizou o questionário top 13');
      }
      setLoading(true);

      // const response = await api.get(
      //   `/students/${id}&${selectedSemester.id}&${id_evauation_stage}/report`
      // );

      const response = await api.post(
        `/students/${id}&${selectedSemester.id}/report`,
        {
          dataMatchedRecomendations,
        }
      );

      const file = new Blob([new Uint8Array(response.data.data)], {
        type: 'application/pdf',
      });

      const fileUrl = window.URL.createObjectURL(file);

      const a = document.createElement('a');
      a.href = fileUrl;
      a.download = `relatório.pdf`;
      a.click();
      a.remove();

      window.URL.revokeObjectURL(fileUrl);
    } catch (error) {
      toast.error('Erro ao gerar pdf');
    } finally {
      setLoading(false);
    }
  }

  async function handleStartAction(id_action) {
    const [id_evaluation_stage] = currentEvaluationData.map(
      item => item.id_evaluation_stage
    );

    try {
      await api.post('/student-actions/start', {
        id_student: id,
        id_action,
        id_evaluation_stage,
      });
      loadStudentInfo();
    } catch (error) {
      toast.error('Erro ao iniciar ação para estudante');
    }
  }

  async function handleStopAction(id_student_action) {
    try {
      await api.delete(`/student-actions/${id_student_action}/stop`);
      loadStudentInfo();
    } catch (error) {
      toast.error('Erro ao pausar ação para estudante');
    }
  }

  async function loadSemesters() {
    try {
      const response = await api.get('/semesters');
      setSemesters(response.data);
    } catch (error) {
      toast.error('Erro ao carregar trimestres');
    }
  }

  useEffect(() => {
    loadSemesters();
  }, []);

  const onSubmit = async data => {
    setIsOpen(false);

    let { realized } = data;

    realized = realized === 'sim';

    try {
      const response = await api.put(`/student-actions/${idAction}`, {
        realized,
        stars,
      });
      if (response.data) {
        toast.warn(response.data.alert);
      } else {
        toast.success('Alterações salvas com sucesso!');
      }
      loadStudentInfo();
    } catch (error) {
      setIsOpen(false);
      setLoading(false);

      toast.error('Erro ao salvar alteração!');
    }
  };

  const pagination = num => {
    if (num < 1) return;
    if (num > evaluations.length) return;

    setCurrentPage(num);
  };

  const ratingChanged = newRating => {
    setStars(newRating);
  };

  return studentData ? (
    <>
      <SectionHead>
        <div>
          <h1>RA: {studentData.student.ra}</h1>
          <MdFileDownload color="#fff" size={20} onClick={downloadPdf} />
        </div>
        <MdArrowBack
          size={20}
          style={{ cursor: 'pointer' }}
          onClick={() => history.push('/students')}
        />
      </SectionHead>
      <Container>
        <div className="general-info">
          <div>
            <strong>
              Turma:
              {` ${studentData.student.class.name} / ${studentData.student.class.serie}`}
            </strong>
            <p>
              {formatedEssayDate
                ? `Última redação realizada em ${formatedEssayDate}`
                : 'Nenhuma redação enviada até o momento'}
            </p>
            <p>
              {formatedTop10Date
                ? `Último questionário top 13 respondido dia ${formatedTop10Date}`
                : 'Nenhum questionário respondido até o momento'}
            </p>
          </div>
          <div>
            <AsyncInput
              name="semester"
              aria-label="id_semester"
              placeholder="Trimestre"
              // loadOptions={loadSemesters}
              value={selectedSemester}
              onChange={semester => loadStudentInfo(semester.id)}
              defaultOptions={semesters}
              isMulti={false}
              cacheOptions
              getOptionValue={option => option.id}
              getOptionLabel={option => option.description}
              noOptionsMessage={() => 'Nenhum trimestre encontrado'}
              required
            />
          </div>
        </div>

        {currentEvaluationData.length > 0 && (
          <>
            <div className="stage-Evaluation">
              <span>Fase Avaliativa</span>
            </div>
            <DivPagination>
              {currentPage > 1 ? (
                <button
                  hide="true"
                  type="button"
                  onClick={() => pagination(currentPage - 1)}
                >
                  <MdKeyboardArrowLeft size="40" />
                </button>
              ) : (
                <MdKeyboardArrowLeft size="40" color="#e2e0e0" />
              )}

              <div className="title">
                {currentEvaluationData.map(evaluation => (
                  <span> {evaluation.title}</span>
                ))}
              </div>

              {currentPage < evaluations.length ? (
                <button
                  type="button"
                  onClick={() => pagination(currentPage + 1)}
                >
                  <MdKeyboardArrowRight size="40" />
                </button>
              ) : (
                <MdKeyboardArrowRight size="40" color="#e2e0e0" />
              )}
            </DivPagination>
          </>
        )}

        <GradeContainer>
          <header>
            <MdBookmark size={20} color="#fff" />
            Notas
          </header>
          <div className="items">
            {studentData.grades.length > 0 ? (
              studentData.grades.map(grade => (
                <div className="item" key={grade.id}>
                  <h1>{grade.school_subject.name}</h1>
                  <p>{grade.pontuation}</p>
                </div>
              ))
            ) : (
              <div className="empty">
                Nenhuma nota importada para este trimestre
              </div>
            )}
          </div>
        </GradeContainer>

        <SpeckContainer>
          <header>
            <>
              <MdBookmark size={20} color="#fff" /> Speck
            </>
          </header>

          <div className="items">
            {currentEvaluationData.length > 0 ? (
              currentEvaluationData.map(speck => (
                <Fragment key={speck.title}>
                  {speck.resSpeck.length > 0 ? (
                    speck.resSpeck.map(item =>
                      item.speck_big5.map(big5 => (
                        <div className="item" key={big5.pontuation}>
                          <h1>{big5.big5.name}</h1>
                          <p>{(Number(big5.pontuation) * 100).toFixed(2)} %</p>
                        </div>
                      ))
                    )
                  ) : (
                    <div className="empty">Avaliação ainda não respondida</div>
                  )}
                </Fragment>
              ))
            ) : (
              <div className="empty">
                Nenhuma redação enviada neste semestre
              </div>
            )}
          </div>
        </SpeckContainer>

        <Top10Container>
          <header hidden={!isTop10}>
            <MdBookmark size={20} color="#fff" />
            TOP 10
          </header>

          <header hidden={isTop10}>
            <>
              <MdBookmark size={20} color="#fff" />
              TOP 13
            </>
          </header>

          <div className="items">
            {currentEvaluationData.length > 0 ? (
              currentEvaluationData.map(top10 => (
                <Fragment key={top10.title}>
                  {top10.resTop10Atributes.length > 0 ? (
                    top10.resTop10Atributes.map(item => (
                      <div className="item" key={item.attribute}>
                        <h1>{item.attribute}</h1>
                        <p>Nível {item.level}</p>
                      </div>
                    ))
                  ) : (
                    <div className="empty">Avaliação ainda não respondida</div>
                  )}
                </Fragment>
              ))
            ) : (
              <div className="empty">
                Nenhum questionário respondido nesse semestre
              </div>
            )}
          </div>
        </Top10Container>

        <RecomendationContainer>
          <header>
            <MdBookmark size={20} color="#fff" />
            Plano de Ações
          </header>
          <div className="recomendations">
            {currentEvaluationData.length > 0 ? (
              currentEvaluationData.map(item =>
                item.matchedRecomendations.length > 0 ? (
                  item.matchedRecomendations.map(recomendation => (
                    <div className="card" key={recomendation.id}>
                      <h1>{recomendation.name}</h1>
                      {recomendation.actions ? (
                        recomendation.actions.map(action => (
                          <Fragment key={action.id}>
                            <div className="action">
                              <p>{action.name}</p>
                              <div className="icons">
                                {!action.student_actions ? (
                                  <>
                                    <p>Iniciar</p>
                                    <MdPlayCircleOutline
                                      size={20}
                                      color="#666"
                                      onClick={() =>
                                        handleStartAction(action.id)
                                      }
                                    />
                                  </>
                                ) : action.student_actions.status ===
                                  'finished' ? (
                                  <div className="buttonContainer1">
                                    <p>Finalizada</p>
                                  </div>
                                ) : (
                                  <>
                                    <div className="buttonContainer1">
                                      <span>Pendente</span>
                                      <div className="buttonContainer2">
                                        <p>Finalizar</p>
                                        <FaRegStopCircle
                                          size={20}
                                          color="#666"
                                          onClick={() =>
                                            handleStopAction(
                                              action.student_actions.id
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                            {action.student_actions &&
                            action.student_actions.status === 'finished' &&
                            action.student_actions.effectiveness === null ? (
                              <div className="buttonContainer">
                                <button
                                  type="button"
                                  onClick={() => {
                                    setIsOpen(true);
                                    setIdAction(action.student_actions.id);
                                  }}
                                >
                                  Avaliar
                                </button>
                              </div>
                            ) : (
                              action.student_actions &&
                              action.student_actions.status === 'finished' && (
                                <div className="buttonContainer">
                                  <Stars
                                    num={action.student_actions.effectiveness}
                                  />
                                </div>
                              )
                            )}
                          </Fragment>
                        ))
                      ) : (
                        <div className="empty-actions">
                          Nenhuma ação criada para essa recomendação
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <div className="empty-recomendations">
                    Nenhuma recomendação para esse aluno neste trimestre
                  </div>
                )
              )
            ) : (
              <div className="empty-recomendations">
                Nenhuma recomendação para esse aluno neste trimestre
              </div>
            )}
          </div>
        </RecomendationContainer>
      </Container>
      <Loader visible={loading} />

      <Modal open={modalIsOpen}>
        <button type="button" onClick={() => setIsOpen(false)}>
          <MdHighlightOff size={30} color="#fff" />
        </button>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <span className="title"> Questionário </span>
          <p>
            Você considera que o plano de ação da recomendação ao estudante foi
            realizado?
          </p>
          <div>
            <input
              id="1"
              type="radio"
              name="realized"
              value="sim"
              ref={register}
              onChange={() => setYes(true)}
              required
            />
            <label htmlFor="1">Sim</label>

            <input
              id="2"
              type="radio"
              name="realized"
              value="nao"
              ref={register}
              onChange={() => {
                setYes(false);
                setStars(0);
              }}
              required
            />
            <label htmlFor="2">Não</label>
          </div>

          {yes && (
            <>
              <p>Avalie a efetividade do plano de ação e recomendação :</p>
              <div>
                <ReactStars
                  name="stars"
                  ref={register}
                  count={5}
                  onChange={ratingChanged}
                  size={28}
                  color2="#ffd700"
                />
              </div>
            </>
          )}

          <button type="submit">Salvar</button>
        </Form>
      </Modal>
    </>
  ) : (
    <Loader visible />
  );
}

Info.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

Info.defaultProps = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: null,
    }),
  }),
};
