/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import PropTypes from 'prop-types';

import produce from 'immer';

import { Container, Big5HeaderGroup, Big5ContentGroup } from './styles';

import RangeInput from '~/components/RangeInput';

export default function CollapseSpeckGroup({
  position,
  recomendation,
  setRecomendation,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const big5 = recomendation.speck ? recomendation.speck[position] : null;

  function handleChange(value, index = null) {
    const newRecomendationState = produce(recomendation, draftState => {
      if (index !== null)
        draftState.speck[position].facets[index].interval = value;
      else draftState.speck[position].interval = value;
    });
    setRecomendation(newRecomendationState);
  }

  return (
    <Container>
      {big5 && (
        <>
          <Big5HeaderGroup isOpen={isOpen}>
            <div onClick={() => setIsOpen(!isOpen)}>
              <p>{big5.speck_big5.name}</p>
              <MdArrowDropDown size={20} color="#333" />
            </div>

            <RangeInput
              value={big5.interval}
              onChange={value => handleChange(value)}
              setValue={value => {
                const newRecomendationState = produce(
                  recomendation,
                  draftState => {
                    draftState.speck[position].interval = value;
                  }
                );
                setRecomendation(newRecomendationState);
              }}
            />
          </Big5HeaderGroup>

          <Big5ContentGroup isOpen={isOpen}>
            {big5.facets.map((facet, index) => (
              <div key={facet.id_speck_facet}>
                <p>{facet.speck_facet.name}</p>
                <RangeInput
                  color="#617be3"
                  value={facet.interval}
                  onChange={value => handleChange(value, index)}
                  setValue={value => {
                    const newRecomendationState = produce(
                      recomendation,
                      draftState => {
                        draftState.speck[position].facets[
                          index
                        ].interval = value;
                      }
                    );
                    setRecomendation(newRecomendationState);
                  }}
                />
              </div>
            ))}
          </Big5ContentGroup>
        </>
      )}
    </Container>
  );
}

CollapseSpeckGroup.propTypes = {
  position: PropTypes.number,
  recomendation: PropTypes.any,
  setRecomendation: PropTypes.func.isRequired,
};

CollapseSpeckGroup.defaultProps = {
  position: 0,
  recomendation: {
    speck: [{ name: 'none' }],
  },
};
