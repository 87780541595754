import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { Form } from '@rocketseat/unform';

export const ContainerSelection = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  margin: 0px 10px 0px 10px;

  span {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  input,
  select {
    padding: 6px 6px;
    border-radius: 6px;
    border: none;
    color: #0074a9;
    border-radius: 6px;
    margin-right: 10px;

    ::-webkit-calendar-picker-indicator {
      cursor: pointer;
    }
  }
  select {
    cursor: pointer;
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const Container = styled.div`
  margin: 24px 64px;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  span {
    font-weight: bold;
  }

  .newEvaluation {
    background: #0074a9;
    padding: 10px 30px;
    border-radius: 5px;
    margin: 10px 0px 0px 5px;
    color: white;
    margin-bottom: 30px;
    font-weight: bold;
    text-decoration: none;
    display: inline-block;

    &:hover {
      background: ${darken(0.06, '#0074a9')};
    }
  }

  .div-pagination {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;

    select {
      padding: 2px;
      border-radius: 5px;
      border: none;
      background: #bbdde0;
      margin-right: 10px;
      margin-left: 10px;
      cursor: pointer;
    }
  }
`;

export const Table = styled.table`
  width: 100%;
  th {
    padding: 10px;
    padding-left: 5px;
    border-bottom: solid 2px #0074a9;
  }
  td {
    padding: 10px;
    padding-left: 5px;
    border-bottom: solid 1px #e2e0e0;
  }

  .verMais {
    color: #4185f4;

    &:hover {
      color: ${darken(0.06, '#4185f4')};
    }
  }

  .alignCenter {
    text-align: center;
  }

  .description {
    background-color: #bbdde0;
  }
`;

export const ActionForm = styled(Form)`
  display: flex;
  flex-direction: column;

  input {
    outline: 0;
    border-width: 0 0 1px;
    border-color: blue;
    margin-left: 10px;
    margin-bottom: 50px;
  }

  button {
    width: 120px;
    background: #0074a9;
    padding: 6px 14px;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    text-decoration: none;

    position: absolute;
    bottom: 10px;
    right: 37px;
    &:hover {
      background: ${darken(0.06, '#0074a9')};
    }
  }
`;

export const ClearButton = styled.button`
  background: #0074a9;
  padding: 8px 20px;
  border-radius: 10px;
  border: 1px #fff solid;
  margin-left: 10px;
  color: white;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;

  &:hover {
    color: ${darken(0.06, '#fff')};
  }
`;

export const Tr = styled.tr`
  /* ${props => props.status === 'Desativada' && css``} */
`;

export const Td = styled.td`
  font-weight: 500;
  color: ${props => {
    if (props.status === 'Desativada') return '#D3D3D3';
    if (props.status === 'Realizada') return '#4169E1';
    if (props.status === 'A ser realizada') return '#FFD700';
    if (props.status === 'Em andamento') return '#228B22';
    return 'default';
  }};
`;
