/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { MdArrowBack } from 'react-icons/md';
import PropTypes from 'prop-types';
import produce from 'immer';
import { toast } from 'react-toastify';

import {
  Container,
  FormHead,
  SpeckForm,
  RecomendationInfoForm,
  Top10List,
  Top10Item,
  SubmitButton,
} from './styles';

import SectionHead from '~/components/SectionHead';
import CollapseSpeckGroup from '~/components/CollapseSpeckGroup';
import Input from '~/components/Input';
import InputRange from '~/components/RangeInput';
import Loader from '~/components/Loader';

import api from '~/services/api';
import history from '~/services/history';

export default function Form({ match }) {
  const [recomendation, setRecomendation] = useState({});
  const [loading, setLoading] = useState(false);
  const [typeRecomendation, setTypeRecomendation] = useState('');

  const { id } = match.params;

  useEffect(() => {
    async function loadRecomendation() {
      if (id) {
        try {
          setLoading(true);
          const response = await api.get(`/recomendations/${id}`);
          setRecomendation(response.data);
        } catch (error) {
          toast.error('Erro ao buscar dados da recomendação');
        } finally {
          setLoading(false);
        }
      } else {
        try {
          setLoading(true);
          const response = await api.get('/recomendations/form');
          setRecomendation(response.data);
        } catch (error) {
          toast.error('Erro ao criar formulário da recomendação');
        } finally {
          setLoading(false);
        }
      }
    }
    loadRecomendation();
  }, [id]);

  async function handleSubmit() {
    if (!recomendation.name) {
      toast.warn('Preencha o nome da recomendação');
      return;
    }
    if (typeRecomendation === '' && !id) {
      toast.warn('Selecione o tipo da recomendação');
      return;
    }

    if (id) {
      try {
        setLoading(true);
        await api.put(`/recomendations/${id}`, recomendation);
        toast.success('Recomendação atualizada');
        history.goBack();
      } catch (error) {
        toast.error('Erro ao atualizar dados da recomendação');
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        await api.post('/recomendations', { recomendation, typeRecomendation });
        toast.success('Recomendação criada com sucesso');
        history.goBack();
      } catch (error) {
        toast.error('Erro ao criar recomendação');
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <>
      <SectionHead>
        <h1>{id ? recomendation.name : 'CRIAR RECOMENDAÇÃO'}</h1>
        <MdArrowBack
          size={20}
          style={{ cursor: 'pointer' }}
          onClick={() => history.goBack()}
        />
      </SectionHead>
      <Container>
        {!id && (
          <div className="recomendation-radio">
            <span> TIPO DA RECOMENDAÇÃO:</span>
            <input
              id="1"
              type="radio"
              name="realized"
              value="speck"
              required
              onChange={() => setTypeRecomendation('speck')}
            />
            <label htmlFor="1">Speck</label>

            <input
              id="2"
              type="radio"
              name="realized"
              value="top13"
              required
              onChange={() => setTypeRecomendation('top13')}
            />
            <label htmlFor="2">Top 13</label>
          </div>
        )}

        <RecomendationInfoForm>
          <Input
            label="NOME RECOMENDAÇÃO"
            name="name"
            value={recomendation.name || ''}
            placeholder="nome da recomendação"
            required
            onChange={e =>
              setRecomendation({ ...recomendation, name: e.target.value })
            }
          />
          <Input
            label="DESCRIÇÃO"
            name="description"
            value={recomendation.description || ''}
            placeholder="descrição da recomendação"
            onChange={e =>
              setRecomendation({
                ...recomendation,
                description: e.target.value,
              })
            }
          />
        </RecomendationInfoForm>
        {recomendation.speck &&
          (typeRecomendation === 'speck' ||
            recomendation.typeRecomendation === 'speck') && (
            <>
              <FormHead>FACETAS SPECK</FormHead>
              <SpeckForm>
                {recomendation.speck.map((big5, index) => (
                  <CollapseSpeckGroup
                    key={big5.id_speck_big5}
                    position={index}
                    recomendation={recomendation}
                    setRecomendation={setRecomendation}
                  />
                ))}
              </SpeckForm>
            </>
          )}
        {recomendation.top10 &&
          (recomendation.typeRecomendation === 'top13' ||
            typeRecomendation === 'top13') && (
            <Top10List>
              <FormHead>ATRIBUTOS TOP 13</FormHead>
              {recomendation.top10.map((top10, index) => (
                <Top10Item key={top10.id_top10_attribute}>
                  <div>{top10.top10_attribute.name}</div>
                  <InputRange
                    value={recomendation.top10[index].interval}
                    setValue={value => {
                      const newRecomendationState = produce(
                        recomendation,
                        draftState => {
                          draftState.top10[index].interval = value;
                        }
                      );
                      setRecomendation(newRecomendationState);
                    }}
                    onChange={interval => {
                      const newRecomendationState = produce(
                        recomendation,
                        draftState => {
                          draftState.top10[index].interval = interval;
                        }
                      );
                      setRecomendation(newRecomendationState);
                    }}
                  />
                </Top10Item>
              ))}
            </Top10List>
          )}
        <SubmitButton type="submit" onClick={handleSubmit}>
          {id ? 'ATUALIZAR' : 'CRIAR'}
        </SubmitButton>
      </Container>
      <Loader visible={loading} />
    </>
  );
}

Form.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
};

Form.defaultProps = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: null,
    }),
  }),
};
