import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Container, Range } from './styles';

export default function RangeInput({ color, value, setValue, ...rest }) {
  const [interval, setInterval] = useState([]);

  useEffect(() => {
    setInterval(value);
  }, [value]);

  return (
    <Container>
      <input
        value={interval[0]}
        onChange={e => {
          if (e.target.value < value[1]) setValue([e.target.value, value[1]]);
        }}
        onBlur={e => {
          const roundedValue =
            e.target.value < 5
              ? Math.ceil(e.target.value / 5) * 5
              : Math.round(e.target.value / 5) * 5;

          setValue([roundedValue, value[1]]);
        }}
      />

      <Range
        {...rest}
        defaultValue={[0, 100]}
        value={value}
        tipFormatter={text => `${text}%`}
        handleStyle={[{ background: color, border: 0 }]}
        trackStyle={[{ background: color }]}
        railStyle={{ background: '#ddd' }}
        step={5}
        pushable
      />

      <input
        value={interval[1]}
        onChange={e => {
          setValue([value[0], e.target.value]);
        }}
        onBlur={e => {
          const roundedValue =
            e.target.value < 5
              ? Math.ceil(e.target.value / 5) * 5
              : Math.round(e.target.value / 5) * 5;

          if (e.target.value >= value[0]) {
            setValue([value[0], roundedValue]);
          } else {
            setValue([value[0], value[0]]);
          }
        }}
      />
    </Container>
  );
}

RangeInput.propTypes = {
  color: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
  setValue: PropTypes.func,
};

RangeInput.defaultProps = {
  color: '#7159c1',
  setValue: () => {},
};
