import styled from 'styled-components';

export const Container = styled.div`
  h1 {
    margin-bottom: 26px;
  }

  .buttons {
    padding-top: 26px;
  }

  button + button {
    background: #0074a9;
    color: #fff;
    padding: 8px 26px;
    border-radius: 8px;
  }
`;
