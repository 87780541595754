import React from 'react';

import { Container } from './styles';

import errorImage from '~/assets/error-404.svg';

export default function NotFound() {
  return (
    <Container>
      <img src={errorImage} alt="Página não encontrada" />
      <p>Esta página não existe...</p>
    </Container>
  );
}
