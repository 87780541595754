import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  bottom: -50%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 350px;
    margin-top: 16px;
  }

  .card {
    display: flex;
    flex-direction: column;
    max-width: 450px;
    margin-top: 40px;
    margin-left: 40px;
    padding: 32px;
    background-color: transparent;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 10px;

    cursor: pointer;
    transition: all 0.4s, background 0.2s;

    p {
      margin-top: 4px;
    }

    svg {
      margin-right: 8px;
      align-self: flex-end;
      margin-top: 8px;
    }

    &:hover {
      transform: translateY(-10px);
      background: #fdfdfd;
    }
  }

  .card2 {
    position: absolute;

    max-width: 450px;
    margin-top: 40px;
    margin-left: 40px;
    padding: 32px;
    background-color: #ffd700;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 10px;
  }
  .card[disabled] {
    background-color: #98e260;
    pointer-events: none;
  }
`;

export const Div = styled.div`
  margin-top: 30px;
  margin-right: 10px;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
`;
