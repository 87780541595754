/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-expressions */

import React, { useEffect, useState } from 'react';
import { MdArrowBack } from 'react-icons/md';
// import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
// import { useSelector } from 'react-redux';
import SectionHead from '~/components/SectionHead';
import Loader from '~/components/Loader';

import history from '~/services/history';
import { Container, ContainerForm, FormStyle } from './styles';
import api from '~/services/api';

export default function Ml() {
  // const { id } = match.params;

  const [dataMlFilter, setDataMlFilter] = useState({});
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit } = useForm();

  // const access_level_id = useSelector(state =>
  //   state.auth && state.auth.user && state.auth.user.access_level
  //     ? state.auth.user.access_level.id
  //     : null
  // );

  useEffect(() => {
    setLoading(true);
    api.get('recomendations-ml').then(response => {
      const { data } = response;

      setDataMlFilter({
        status_speck: data.status_speck,
        status_top13: data.status_top13,
        top13_percent: Number(data.top13_percent * 100),
        speck_percent: Number(data.speck_percent * 100),
      });
      setLoading(false);
    });
  }, []);

  const onSubmit = async data => {
    setLoading(true);

    try {
      await api.put('recomendations-ml', { data });
      toast.success('Alterações salvas com sucesso!');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('Erro ao salvar alterações!');
    }
  };

  return (
    <>
      <SectionHead>
        <h1>Machine Learning</h1>
        <MdArrowBack
          size={20}
          style={{ cursor: 'pointer' }}
          onClick={() => history.goBack()}
        />
      </SectionHead>

      <Container>
        <FormStyle onSubmit={handleSubmit(onSubmit)}>
          <ContainerForm>
            {/* <div className="input-div">
              <span>Porcentagem Speck</span>
              <input
                type="number"
                defaultValue={dataMlFilter ? dataMlFilter.speck_percent : 0}
                name="speck_percent"
                label="Speck"
                ref={register}
                min="0"
                max="100"
                required
              />
              <div className="div-switch">
                <label className="switch">
                  <input
                    checked={dataMlFilter ? dataMlFilter.status_speck : false}
                    onChange={event => {
                      const { checked } = event.target;
                      setDataMlFilter({
                        ...dataMlFilter,
                        status_speck: checked,
                      });
                    }}
                    type="checkbox"
                    name="status_speck"
                    label="Speck"
                    ref={register}
                  />
                  <span className="slider round" />
                </label>
              </div>
            </div> */}

            <div className="input-div">
              <span>Porcentagem Top 13</span>
              <input
                type="number"
                defaultValue={dataMlFilter ? dataMlFilter.top13_percent : 0}
                name="top13_percent"
                label="Top13"
                ref={register}
                min="0"
                max="100"
                required
              />
              <div className="div-switch">
                <label className="switch">
                  <input
                    checked={dataMlFilter ? dataMlFilter.status_top13 : false}
                    onChange={event => {
                      const { checked } = event.target;
                      setDataMlFilter({
                        ...dataMlFilter,
                        status_top13: checked,
                      });
                    }}
                    type="checkbox"
                    name="status_top13"
                    label="Top13"
                    ref={register}
                  />
                  <span className="slider round" />
                </label>
              </div>
            </div>

            <div className="button-div">
              <button type="submit">Aplicar</button>{' '}
            </div>
          </ContainerForm>
        </FormStyle>

        <Loader visible={loading} />
      </Container>
    </>
  );
}

// Ml.propTypes = {
//   match: PropTypes.shape({
//     params: PropTypes.shape({
//       id: PropTypes.string,
//     }),
//   }),
// };

// Ml.defaultProps = {
//   match: PropTypes.shape({
//     params: PropTypes.shape({
//       id: null,
//     }),
//   }),
// };
