import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { signInSuccess, signInFailure, updateAnswers } from './actions';
import api from '~/services/api';
import history from '~/services/history';

export function* setToken({ payload }) {
  if (!payload || !payload.student) return;

  if (payload.student.answers) {
    yield put(updateAnswers(payload.student.answers));
  }

  const { student_token } = payload.student;

  if (student_token) {
    api.defaults.headers.student = student_token;
  }
}

export function* signIn({ payload }) {
  try {
    const { ra, unidade, turma } = payload;

    const response = yield call(api.post, '/student/sessions', {
      ra,
      unidade,
      turma,
    });

    const { token, student } = response.data;

    api.defaults.headers.student = token;

    yield put(signInSuccess(token, student));

    history.push('/student/home');
  } catch (error) {
    toast.error('Credenciais inválidas, verifique seus dados!');
    yield put(signInFailure());
  }
}

export function singOut() {
  history.push('/student/auth');
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@student/SIGN_IN_REQUEST', signIn),
  takeLatest('@student/SIGN_OUT', singOut),
]);
