import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin: 16px 26px;
  background: ${props => (props.isChecked ? '#0074a91f' : '#fff')};

  h1 {
    margin-bottom: 10px;
  }
`;

export const AnswerContainer = styled.div`
  margin: 4px 8px;
  display: flex;
  align-items: center;

  input {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  label {
    margin-left: 4px;
    cursor: pointer;
  }
`;
