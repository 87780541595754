import styled from 'styled-components';
import { Collapse } from 'reactstrap';

export const Container = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
`;

export const Big5HeaderGroup = styled.div`
  & > div:first-child {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 8px;

    p {
      font-size: 20px;
    }

    svg {
      margin-left: 6px;
      transform: ${props => props.isOpen && 'rotate(180deg)'};
      transition: all 0.3s;
    }
  }
`;

export const Big5ContentGroup = styled(Collapse)`
  padding: 16px;
  font-size: 14px;

  & > div {
    margin-top: 8px;
  }
`;
