/* eslint-disable no-unused-vars */
/* eslint-disable no-return-assign */

import React, { useMemo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MdSend } from 'react-icons/md';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import {
  Container,
  SectionHead,
  TextArea,
  LoadBar,
  SubmitButton,
} from './styles';

import Loader from '~/components/Loader';

import api from '~/services/api';
import history from '~/services/history';
import StudentHeader from '~/components/Header/StudentHeader';

import { updateEssay } from '~/store/modules/student/actions';

const MAX_SIZE_WORDS = 600;

export default function Speck({ match }) {
  const { id, id_semester } = match.params;

  const [loading, setLoading] = useState(false);

  const text = useSelector(state => state.student.essay);
  const dispatch = useDispatch();

  const countText = useMemo(() => {
    return text === ''
      ? 0
      : text
          .trim()
          .replace(/ {2,}/g, ' ')
          .split(' ').length;
  }, [text]);

  const percentTextWrote = useMemo(() => {
    return (countText / MAX_SIZE_WORDS) * 100;
  }, [countText]);

  useEffect(() => {
    if (!api.defaults.headers.student) {
      history.push('/student/expired-session');
    }
  });

  async function handleSubmit() {
    try {
      if (countText < 600) {
        return toast.warn('A redação deve ter no mínimo 600 palavras');
      }
      setLoading(true);
      await api.post('/student/essay', { text, id, id_semester });

      dispatch(updateEssay(''));
      setLoading(false);
      history.push('/student/home');

      toast.success('Redação enviada com sucesso');
    } catch (error) {
      setLoading(false);
      toast.error(
        'Erro ao enviar redação, certifique que a redação já não foi enviada para este trimestre'
      );
    }
  }

  return (
    <>
      <StudentHeader />
      <SectionHead>
        <h1>REDAÇÃO SPECK</h1>
        <div className="description">
          <span>Escreva abaixo sua redação para ser avaliada pelo SPECK</span>
          <p>O tema da redação é livre, e deve conter no mínimo 600 palavras</p>
        </div>
      </SectionHead>

      <Container>
        <p className="instructions">
          Escreva uma redação de no mínimo 600 palavras. Você não precisa se
          preocupar com a pontuação. Escreva um texto mais livre. O tema da
          redação encontra-se a seguir.
          <br />
          <br />
          <b>Tema:</b> Aspirações(sonhos) para o ano de 2021.
          <br />
          <br />
          Descreva os seus sonhos com as pessoas que você gosta para o ano de
          2021.
        </p>
        <div className="textBox">
          <TextArea
            name="speck-text"
            spellCheck="false"
            value={text}
            onChange={e => dispatch(updateEssay(e.target.value))}
          />
          <span className="total-text">{countText}</span>
        </div>
        <LoadBar percent={percentTextWrote}>
          <div className="percent-loaded" />
        </LoadBar>
        <SubmitButton icon={<MdSend size={20} />} onClick={handleSubmit}>
          <span>Submeter</span>
        </SubmitButton>
      </Container>
      <Loader visible={loading} />
    </>
  );
}

Speck.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

Speck.defaultProps = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: null,
    }),
  }),
};
