import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MdSend } from 'react-icons/md';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';
import { Container, SubmitButton } from './styles';

import Loader from '~/components/Loader';

import Question from './Question';

import api from '~/services/api';
import history from '~/services/history';
import StudentHeader from '~/components/Header/StudentHeader';

import { updateAnswers } from '~/store/modules/student/actions';

export default function Top10({ match }) {
  const { id, id_semester } = match.params;
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);

  const answers = useSelector(state => state.student.answers);
  const dispatch = useDispatch();

  const formRef = useRef();

  async function handleSubmit() {
    try {
      setLoading(true);

      await api.post('/student/questions', {
        answers,
        id,
        id_semester,
      });
      history.push('/student/home');
      dispatch(updateAnswers([]));
      setLoading(false);
      toast.success('Respostas enviadas com sucesso');
    } catch (error) {
      setLoading(false);
      toast.error(
        'Erro ao enviar respostas, certifique que essas respostas já não foram enviada para este trimestre'
      );
    }
  }

  useEffect(() => {
    if (!api.defaults.headers.student) {
      history.push('/student/expired-session');
    }
  });

  useEffect(() => {
    async function loadQuestion() {
      try {
        const response = await api.get('/student/questions');

        setQuestions(response.data);
      } catch (error) {
        toast.error('Erro ao buscar perguntas');
      }
    }
    loadQuestion();
  }, []);

  return (
    <>
      <StudentHeader />
      <Container>
        <form ref={formRef} onSubmit={e => e.preventDefault()}>
          {questions.map(data => (
            <Question key={data.id} data={data} answers={answers} />
          ))}
          <SubmitButton
            icon={<MdSend size={20} />}
            type="submit"
            onClick={() => {
              if (formRef.current.checkValidity()) {
                handleSubmit();
              } else {
                toast.warn('Responda todas as perguntas');
              }
            }}
          >
            <span>ENVIAR</span>
          </SubmitButton>
        </form>
      </Container>
      <Loader visible={loading} />
    </>
  );
}

Top10.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

Top10.defaultProps = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: null,
    }),
  }),
};
