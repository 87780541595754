import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

import { Container } from './styles';

export default function InputComponent({
  className,
  errorMessage,
  label,
  name,
  mask,
  ...rest
}) {
  const ref = useRef();

  const [showError, setShowError] = useState(false);

  useEffect(() => {
    const inputElement = mask ? ref.current.inputElement : ref.current;
    if (inputElement) {
      inputElement.addEventListener('blur', e => {
        const { valid } = e.target.validity;
        setShowError(!valid);
      });

      // inputElement.addEventListener('change', e => {
      //   const { valid } = e.target.validity;
      //   setShowError(!valid);
      // });
    }
  }, [mask]);

  return (
    <Container className={className}>
      <label htmlFor={name}>{label}</label>
      {mask ? (
        <MaskedInput
          mask={mask}
          id={name}
          name={name}
          ref={ref}
          {...rest}
          onInvalid={e => e.preventDefault()}
        />
      ) : (
        <input
          id={name}
          name={name}
          ref={ref}
          {...rest}
          onInvalid={e => e.preventDefault()}
        />
      )}
      {showError && <span>{errorMessage}</span>}
    </Container>
  );
}

InputComponent.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  name: PropTypes.string.isRequired,
  mask: PropTypes.any,
};

InputComponent.defaultProps = {
  label: null,
  className: null,
  errorMessage: 'Campo inválido',
  mask: null,
};
