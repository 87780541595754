import styled, { css } from 'styled-components';
import Select from 'react-select';
import { darken } from 'polished';

export const Container = styled.div`
  margin: 24px 64px;

  .activate-all {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;

    .div-activate-all {
      display: flex;
      align-items: center;
    }

    span {
      font-weight: 600;
    }

    .div-switch {
      margin: 15px;
    }
  }

  .div-pagination {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 30px;

    select {
      padding: 2px;
      border-radius: 5px;
      border: none;
      background: #bbdde0;
      margin-right: 10px;
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .footer {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;

    .student-count {
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        margin-left: 30px;
        border-bottom: 1px #e2e0e0 solid;

        p {
          margin-top: 5px;
          text-align: center;
        }
        span {
          font-weight: 600;
        }
      }
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 18px;
  }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 24px;
    width: 24px;
    bottom: -2.5px;
    border: 1px solid #27a1ca;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #27a1ca;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #27a1ca;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  .slider.round {
    border-radius: 34px;
  }
  .slider.round:before {
    border-radius: 50%;
  }

  .new-search {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
`;

export const ContainerSelection = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin: 0px 10px 0px 10px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const SelectStyles = styled(Select)`
  width: 120px;
  margin-right: 10px;
  border-radius: 8px;
  color: #0074a9;

  .css-1uccc91-singleValue {
    color: #0074a9;
    padding: 1;
  }
  .css-yk16xz-control {
    cursor: pointer;
  }
  .css-1wa3eu0-placeholder {
    color: #0074a9;
  }

  .css-1hb7zxy-IndicatorsContainer {
    span {
      display: none;
    }
    .css-tlfecz-indicatorContainer {
      color: #0074a9;
    }
  }

  @media (max-width: 600px) {
    margin-bottom: 5px;
  }
`;

export const Table = styled.table`
  width: 100%;
  th {
    padding: 10px;
    padding-left: 5px;
    border-bottom: solid 2px #0074a9;
  }
  td {
    padding: 10px;
    padding-left: 5px;
    border-bottom: solid 1px #e2e0e0;
  }
`;

export const Button = styled.button`
  background: #0074a9;
  padding: 10px 30px;
  border-radius: 5px;
  margin: 10px 0px 0px 5px;
  color: white;
  margin-bottom: 30px;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;

  &:hover {
    background: ${darken(0.06, '#0074a9')};
  }
`;

export const ClearButton = styled.button`
  background: #0074a9;
  padding: 8px 20px;
  border-radius: 10px;
  border: 1px #fff solid;
  margin-left: 10px;
  color: white;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;

  &:hover {
    color: ${darken(0.06, '#fff')};
  }
`;

export const Tr = styled.tr`
  ${props =>
    props.status &&
    css`
      background-color: #bbdde0;
    `}
`;
