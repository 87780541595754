import React, { useState, useEffect, useRef } from 'react';
import { MdArrowBack } from 'react-icons/md';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { Container, ActionForm, AsyncInput, SubmitButton } from './styles';

import SectionHead from '~/components/SectionHead';
import Input from '~/components/Input';
import Loader from '~/components/Loader';

import api from '~/services/api';
import history from '~/services/history';

export default function Form({ match }) {
  const [loading, setLoading] = useState(false);

  const formRef = useRef();

  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedAccessLevel, setSelectedAccessLevel] = useState(null);

  const [defaultUnitOptions, setDefaultUnitOptions] = useState([]);
  const [defaultAccessLevelOptions, setDefaultAccessLevelOptions] = useState(
    []
  );

  const { id } = match.params;

  useEffect(() => {
    async function loadDefaultUnitsOptions() {
      try {
        const response = await api.get('/units');
        return setDefaultUnitOptions(response.data);
      } catch (error) {
        return toast.error('Erro ao listar unidades');
      }
    }

    async function loadDefaultAccessLevelOptions() {
      try {
        const response = await api.get('/access-levels');
        return setDefaultAccessLevelOptions(response.data);
      } catch (error) {
        return toast.error('Erro ao listar níveis de acesso');
      }
    }

    loadDefaultUnitsOptions();
    loadDefaultAccessLevelOptions();
  }, []);

  useEffect(() => {
    function setInputValue(inputName, value) {
      const input = document.querySelector(`input[name=${inputName}]`);
      input.value = value;
    }

    async function loadUser() {
      if (id) {
        try {
          setLoading(true);
          const response = await api.get(`/users/${id}`);

          setInputValue('name', response.data.name);
          setInputValue('email', response.data.email);
          setInputValue('cpf', response.data.cpf);

          setLoading(response.data);
          setSelectedUnit(response.data.unit);
          setSelectedAccessLevel(response.data.access_level);
        } catch (error) {
          toast.error('Erro ao buscar dados do usuário');
        } finally {
          setLoading(false);
        }
      }
    }
    loadUser();
  }, [id]);

  async function handleSubmit(e) {
    function getInputValue(name) {
      const input = document.querySelector(`input[name=${name}]`);
      return input ? input.value : undefined;
    }

    const formIsValid = formRef.current.checkValidity();

    if (!formIsValid)
      return toast.warn('Preencha todo o formulário corretamente');

    const name = getInputValue('name');
    const email = getInputValue('email');
    const cpf = getInputValue('cpf')
      .match(/[0-9]/gi)
      .join('');
    const password = getInputValue('password');

    const id_access_level = selectedAccessLevel ? selectedAccessLevel.id : null;
    const id_unit = selectedUnit ? selectedUnit.id : null;

    if (!id_access_level || !id_unit)
      return toast.warn('Seleciona todas as opções do formulário corretamente');

    if (id) {
      try {
        setLoading(true);
        await api.put(`/users/${id}`, {
          name,
          email,
          cpf,
          password,
          id_access_level,
          id_unit,
        });
        toast.success('Usuário atualizado');
        history.push('/users');
      } catch (error) {
        if (error.response.status === 400) {
          toast.error('Usuário já existe');
        } else {
          toast.error('Erro ao atualizar usuário');
        }
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        await api.post('/users', {
          name,
          email,
          cpf,
          password,
          id_access_level,
          id_unit,
        });
        toast.success('Usuário criado com sucesso');
        history.push('/users');
      } catch (error) {
        if (error.response.status === 400) {
          toast.error('Usuário já existe');
        } else {
          toast.error('Erro ao criar usuário');
        }
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <>
      <SectionHead>
        <h1>{id ? 'ATUALIZAR USUÁRIO' : 'CRIAR USUÁRIO'}</h1>
        <MdArrowBack
          size={20}
          style={{ cursor: 'pointer' }}
          onClick={() => history.push('/users')}
        />
      </SectionHead>
      <Container>
        <ActionForm ref={formRef}>
          <Input
            label="NOME"
            name="name"
            placeholder="nome do usuário"
            errorMessage="Nome é obrigatório"
            required
          />
          <Input
            label="EMAIL"
            name="email"
            placeholder="email do usuário"
            type="email"
            errorMessage="Digite um email válido"
            required
          />
          <Input
            label="CPF"
            name="cpf"
            placeholder="cpf do usuário"
            pattern="[0-9]{3}[\.][0-9]{3}[\.][0-9]{3}[\-][0-9]{2}"
            mask={[
              /\d/,
              /\d/,
              /\d/,
              '.',
              /\d/,
              /\d/,
              /\d/,
              '.',
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
            ]}
            errorMessage="Digite um cpf válido"
            required
          />
          <label>NÍVEL DE ACESSO</label>
          <AsyncInput
            name="access_level"
            aria-label="id_access_level"
            placeholder="nível de acesso"
            // loadOptions={loadRecomendations}
            value={selectedAccessLevel}
            onChange={updatedValue => setSelectedAccessLevel(updatedValue)}
            defaultOptions={defaultAccessLevelOptions}
            isMulti={false}
            cacheOptions
            getOptionValue={option => option.id}
            getOptionLabel={option => option.name}
            noOptionsMessage={() => 'Nenhum nível de acesso encontrado'}
            required
          />
          <label>UNIDADE</label>
          <AsyncInput
            name="unit"
            aria-label="id_unit"
            placeholder="unidade do usuário"
            // loadOptions={loadRecomendations}
            value={selectedUnit}
            onChange={updatedValue => setSelectedUnit(updatedValue)}
            defaultOptions={defaultUnitOptions}
            isMulti={false}
            cacheOptions
            getOptionValue={option => option.id}
            getOptionLabel={option => option.name}
            noOptionsMessage={() => 'Nenhuma unidade encontrada'}
            required
          />
          <Input
            label="SENHA"
            type="password"
            name="password"
            placeholder="senha do usuário"
            errorMessage="Digite uma senha válida"
            required
          />
        </ActionForm>
        <SubmitButton type="submit" onClick={handleSubmit}>
          {id ? 'ATUALIZAR' : 'CRIAR'}
        </SubmitButton>
      </Container>
      <Loader visible={loading} />
    </>
  );
}

Form.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

Form.defaultProps = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: null,
    }),
  }),
};
