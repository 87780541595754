import React from 'react';
import { Container, Overlay } from './styles';

function Modal({ open, children }) {
  if (!open) return false;
  return (
    <>
      <Overlay />
      <Container>{children}</Container>
    </>
  );
}

export default Modal;
