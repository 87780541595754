import styled from 'styled-components';

const List = styled.ul`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;

  li {
    display: flex;
    align-items: center;
    background: #fff;
    box-shadow: 1px 1px 3px 1px #dedede;
    border-radius: 4px;
    overflow: hidden;
    min-width: 250px;

    .options {
      display: flex;
      margin: auto 18px auto auto;
      align-items: center;

      svg {
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }

  @media (max-width: 1000px) {
    & {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (max-width: 600px) {
    & {
      grid-template-columns: 1fr;

      li .options {
        margin: auto 4px auto auto;

        svg {
          margin-left: 0px;
        }
      }
    }
  }
`;

export default List;
