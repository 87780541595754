import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px 40px;
  z-index: 1000;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.7);

  & > button {
    background-color: #4185f4;
    border-radius: 50px;
    position: absolute;
    top: -10px;
    right: -10px;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
`;
