/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, Fragment } from 'react';
import { MdArrowForward, MdArrowBack } from 'react-icons/md';
import { toast } from 'react-toastify';

import pt from 'date-fns/locale/pt';
import { format, parseISO } from 'date-fns';
import { Container, Div } from './styles';

import Pagination from '~/components/Pagination';

import studentsVector from '~/assets/students-home.svg';
import StudentHeader from '~/components/Header/StudentHeader';

import api from '~/services/api';
import history from '~/services/history';

export default function Home() {
  const [perPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [status, setStatus] = useState([]);
  const [errorRender, setErrorRender] = useState(false);

  useEffect(() => {
    if (!api.defaults.headers.student) {
      history.push('/student/auth');
    }
  });

  useEffect(() => {
    try {
      api.get('/student/status').then(response => {
        const { data } = response;
        if (data.error) {
          setErrorRender(true);
        } else {
          const dataResult = data.map(item => {
            const startDate = format(
              parseISO(item.start_date),
              "dd'/'MM'/'yyyy",
              {
                locale: pt,
              }
            );

            const endDate = format(parseISO(item.end_date), "dd'/'MM'/'yyyy", {
              locale: pt,
            });

            return {
              essay: item.essay,
              top10: item.top10,
              id_evaluation: item.id_evaluation,
              id_semester: item.id_semester,
              title: item.title,
              start_date: startDate,
              end_date: endDate,
            };
          });

          const res = dataResult.sort(a => {
            if (a.essay || a.top10) {
              return 1;
            }
            if (!a.essay || !a.top10) {
              return -1;
            }
            return 0;
          });

          setStatus(res);
        }
      });
    } catch (error) {
      toast.error('Erro ao buscar status do aluno');
      setStatus({ error: true });
      setErrorRender(true);
    }
  }, []);

  const pages = Math.ceil(status.length / perPage);
  const indexOfLastPost = currentPage * perPage;
  const indexOfFirstPost = indexOfLastPost - perPage;
  const currentStatus = status.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <>
      <StudentHeader />
      <Container>
        <img src={studentsVector} alt="students" />
        {currentStatus.length > 0
          ? currentStatus.map(item => (
              <Fragment key={item.id_evaluation}>
                <div
                  className="card"
                  onClick={() =>
                    history.push(
                      `/student/speck/${item.id_evaluation}&${item.id_semester}`
                    )
                  }
                  disabled={item.essay}
                >
                  <h1>Redação SPECK</h1>
                  <p>
                    Escreva uma redação de no mínimo 600 palavras. Você não
                    precisa se preocupar com a pontuação. Escreva um texto mais
                    livre. O tema da redação encontra-se a seguir.
                  </p>
                  <br />
                  <p>Tema: Aspirações(sonhos) para o ano de 2021.</p>
                  <br />
                  <p>
                    Descreva os seus sonhos com as pessoas que você gosta para o
                    ano de 2021.
                  </p>
                  <br />
                  <span>Etapa : {item.title}</span>
                  <span>Data final de entrega: {item.end_date} </span>
                  <MdArrowBack size={20} />
                </div>

                <div
                  className="card"
                  disabled={item.top10}
                  onClick={() =>
                    history.push(
                      `/student/top10/${item.id_evaluation}&${item.id_semester}`
                    )
                  }
                >
                  <h1>Perguntas TOP 13</h1>
                  <p>
                    Responda o questionário a seguir, clicando na resposta que
                    mais se aproxima do que você pensa. Não há resposta certa ou
                    errada. Seja verdadeiro. Responda as perguntas, pensando na
                    sua própria realidade vivida e não imaginando aquela que
                    você desejaria. Todas as perguntas precisam ser respondidas.
                  </p>

                  <br />
                  <span>Etapa : {item.title}</span>
                  <span>Data final de entrega: {item.end_date}</span>
                  <MdArrowForward size={20} />
                </div>
              </Fragment>
            ))
          : errorRender && (
              <div className="card2">
                <h1>Redação SPECK / Perguntas TOP 13</h1>
                <p>No momento as funcionalidades não estão disponíveis.</p>
              </div>
            )}
      </Container>
      {!errorRender && (
        <Div className="pagination">
          <span>Páginas:</span>
          <Pagination
            pages={pages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Div>
      )}
    </>
  );
}
