import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { Container } from './styles';

import Input from '~/components/Input';
import Loader from '~/components/Loader';

import api from '~/services/api';

export default function CsvDialog({ onClose, filter }) {
  const [semesterYear, setSemesterYear] = useState(new Date().getFullYear());
  const [loading, setLoading] = useState(false);

  function generateCSV({ csv, title = 'relatório' }) {
    const a = document.createElement('a');
    a.href = `data:text/csv;charset=utf-8, ${encodeURI(csv)}`;
    a.target = '_blank';
    a.download = `${title}.csv`;
    a.click();
    a.remove();
  }

  async function handleImport() {
    try {
      setLoading(true);
      const response = await api.get('/students-csv', {
        params: {
          semesterYear,
          filter,
        },
      });

      generateCSV({ csv: response.data.gradesCsv, title: 'Notas' });
      generateCSV({ csv: response.data.speckCsv, title: 'Speck' });
      generateCSV({ csv: response.data.top10Csv, title: 'Top13' });
    } catch (error) {
      toast.error('Erro ao gera arquivos CSV');
    } finally {
      setLoading(false);
      onClose();
    }
  }

  return (
    <>
      <Container>
        <h1>Exportar dados dos alunos em CSV</h1>
        <Input
          name="semesterYear"
          placeholder="Digite o ano desejado"
          label="ANO EXPORTAÇÃO"
          value={semesterYear}
          onChange={e => setSemesterYear(e.target.value)}
          pattern="[0-9]{4}"
          mask={[/\d/, /\d/, /\d/, /\d/]}
          errorMessage="Digite um ano válido"
          required
        />
        <div className="buttons">
          <button type="button" onClick={onClose}>
            Cancelar
          </button>
          <button type="button" onClick={handleImport}>
            Baixar CSV
          </button>
        </div>
      </Container>
      <Loader visible={loading} />
    </>
  );
}

CsvDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  filter: PropTypes.string,
};

CsvDialog.defaultProps = {
  filter: '',
};
