import styled from 'styled-components';
import { Form as FormWrapper } from '@rocketseat/unform';
import ButtonWrapper from '~/components/Button';

import background from '~/assets/background.svg';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  background: url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
`;

export const Card = styled.div`
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 32px 46px;
  border-radius: 8px;
  box-shadow: 0 0 2px 1px #ddda;

  .art {
    width: 320px;
    margin-left: 26px;
  }
`;

export const Form = styled(FormWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    align-self: flex-end;
  }

  .images {
    display: flex;

    img {
      height: 55px;
    }

    img + img {
      margin-left: 24px;
    }
  }

  & > div {
    margin-top: 16px;
  }

  @media (max-width: 890px) {
    .images {
      flex-direction: column;

      img + img {
        margin-left: 0;
      }
    }
  }

  @media (max-width: 890px) {
    & {
      width: 80%;

      .images {
        img {
          height: 42px;
        }
      }
    }
  }
`;

export const Button = styled(ButtonWrapper)`
  background: #0074a9;
  color: #fff;
  margin-top: 16px;
`;
