export function singInRequest(ra, unidade, turma) {
  return {
    type: '@student/SIGN_IN_REQUEST',
    payload: { ra, unidade, turma },
  };
}

export function signInSuccess(student_token, student) {
  return {
    type: '@student/SIGN_IN_SUCCESS',
    payload: { student_token, student },
  };
}

export function signInFailure() {
  return {
    type: '@student/SIGN_IN_FAILURE',
  };
}

export function updateAnswers(answers) {
  return {
    type: '@student/UPDATE_ANSWERS',
    payload: { answers },
  };
}

export function updateEssay(essay) {
  return {
    type: '@student/UPDATE_ESSAY',
    payload: { essay },
  };
}

export function singOut() {
  return {
    type: '@student/SIGN_OUT',
  };
}
