import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { Container, Card, Form, Button } from './styles';

import { InputComponent } from '~/components/Unform/Input';

import logoColegioBatista from '~/assets/logo-cb.png';
// import logoGestaoInovacoes from '~/assets/logo-gi.jpeg';
import loginArt from '~/assets/login-art.jpg';

import Loader from '~/components/Loader';

import { singInRequest } from '~/store/modules/auth/actions';

const schema = Yup.object().shape({
  email: Yup.string('Digite um email válido')
    .email('Digite um email válido')
    .required('Email é obrigatório'),
  password: Yup.string('Digite uma senha válida').required(
    'Senha é obrigatória'
  ),
});

export default function Login() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  function handleSubmit({ email, password }) {
    dispatch(singInRequest(email, password));
  }

  return (
    <>
      <Container>
        <Card>
          <Form onSubmit={handleSubmit} schema={schema}>
            <div className="images">
              <img src={logoColegioBatista} alt="Colégio Batista" />
              {/* <img src={logoGestaoInovacoes} alt="Gestão e Inovações" /> */}
            </div>
            <InputComponent
              name="email"
              placeholder="exemplo@gmail.com"
              label="EMAIL"
              required
            />
            <InputComponent
              name="password"
              placeholder="*******"
              type="password"
              label="SENHA"
              required
            />
            <Button type="submit">Entrar</Button>
          </Form>
          <img className="art" src={loginArt} alt="Faça seu login" />
        </Card>
      </Container>
      <Loader visible={loading} />
    </>
  );
}
