import produce from 'immer';

const INITIAL_STATE = {
  student: null,
  student_token: null,
  student_signed: false,
  failed: false,
  answers: [],
  essay: '',
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@student/SIGN_IN_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@student/SIGN_IN_SUCCESS': {
        draft.student = action.payload.student;
        draft.student_token = action.payload.student_token;
        draft.student_signed = true;
        draft.failed = false;
        draft.loading = false;
        break;
      }
      case '@student/SIGN_IN_FAILURE': {
        draft.student = null;
        draft.student_token = null;
        draft.student_signed = false;
        draft.failed = true;
        draft.loading = false;

        break;
      }
      case '@student/UPDATE_ANSWERS': {
        draft.answers = action.payload.answers;
        break;
      }
      case '@student/UPDATE_ESSAY': {
        draft.essay = action.payload.essay;
        break;
      }
      // case 'persist/REHYDRATE': {
      //   draft.failed = false;
      //   draft.loading = false;
      //   break;
      // }
      case '@student/SIGN_OUT': {
        draft.student_token = null;
        draft.student_signed = false;
        draft.loading = false;
        draft.student = null;
        break;
      }
      default:
        break;
    }
  });
}
