import styled from 'styled-components';

export const Container = styled.div`
  display: inline-block;
  width: 80px;
  height: 80px;
  position: fixed;
  left: calc(50% - 40px);
  top: calc(50% - 40px);
  opacity: ${props => (props.visible ? 1 : 0)};
  z-index: ${props => (props.visible ? 0 : -50)};
  transition: background, opacity 0.5s;

  span {
    margin-bottom: 20px;
    opacity: 1;
    font-size: 18px;
  }

  &:after {
    content: '';
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #0074a9;
    border-color: #0074a9 transparent #0074a9 transparent;
    animation: dual-ring 1.2s linear infinite;
    z-index: 9999;
  }

  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #0002;
    z-index: 25;
  }

  @keyframes dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
