/* eslint-disable no-plusplus */
import React from 'react';
import { MdStar } from 'react-icons/md';

function Stars({ num }) {
  const stars = [];

  for (let index = 0; index < num; index++) {
    stars.push(<MdStar color="yellow" />);
  }

  return <div>{stars}</div>;
}

export default Stars;
