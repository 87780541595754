import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { Container, Card, Form, Button } from './styles';

import {
  InputComponent,
  SelectComponent,
} from '~/components/Unform/Input/index';

import logoColegioBatista from '~/assets/logo-cb.png';
// import logoGestaoInovacoes from '~/assets/logo-gi.jpeg';
import loginArt from '~/assets/login-art.jpg';
import { singInRequest } from '~/store/modules/student/actions';
import api from '~/services/api';

const schema = Yup.object().shape({
  RA: Yup.string('Digite um RA válido').required('Campo RA é obrigatório'),
  Unidade: Yup.string('Digite uma unidade válida').required(
    'Campo Unidade é obrigatório'
  ),
  Turma: Yup.string('Digite uma Turma válida').required(
    'Campo Turma é obrigatório'
  ),
});

export default function Login() {
  const [classes, setClasses] = useState([]);
  const [units, setUnits] = useState([]);
  const dispatch = useDispatch();
  // const loading = useSelector(state => state.student.loading);

  function handleSubmit({ RA, Unidade, Turma }) {
    const add = 9 - RA.length;
    const RAresult = Array(add).join('0') + RA;

    dispatch(singInRequest(RAresult, Unidade, Turma));
  }

  useEffect(() => {
    api.get('/classes').then(response => {
      const { data } = response;

      const result = data.map(classe => ({
        id: classe.name,
        title: classe.name,
      }));

      setClasses(result);
    });

    api.get('/units').then(response => {
      const { data } = response;

      const result = data.map(unit => ({
        id: unit.name,
        title: unit.name,
      }));

      setUnits(result);
    });
  }, []);

  return (
    <>
      <Container>
        <Card>
          <Form onSubmit={handleSubmit} schema={schema}>
            <div className="images">
              <img src={logoColegioBatista} alt="Colégio Batista" />
              {/* <img src={logoGestaoInovacoes} alt="Gestão e Inovações" /> */}
            </div>
            <InputComponent name="RA" placeholder="RA" label="RA:" required />
            <SelectComponent
              options={units}
              name="Unidade"
              placeholder="Unidade"
              label="UNIDADE:"
              required
            />
            <SelectComponent
              options={classes}
              name="Turma"
              placeholder="Turma"
              label="TURMA:"
              required
            />
            <Button type="submit">Entrar</Button>
          </Form>
          <img className="art" src={loginArt} alt="Faça seu login" />
        </Card>
      </Container>
      {/* <Loader visible={loading} /> */}
    </>
  );
}
