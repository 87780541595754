import styled from 'styled-components';
import Slider from 'rc-slider';

const { createSliderWithTooltip } = Slider;
const RangeWrapper = createSliderWithTooltip(Slider.Range);

export const Container = styled.div`
  display: flex;
  align-items: center;

  input {
    width: 42px;
    margin: 16px;
    border-radius: 8px;
    border: 1px solid #ddd;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    padding: 4px 6px;
  }
`;

export const Range = styled(RangeWrapper)``;
