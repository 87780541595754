import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Login from '~/pages/administrators/Login';

import Actions from '~/pages/administrators/Actions';
import ActionsForm from '~/pages/administrators/Actions/Form';
import Ml from '~/pages/administrators/Actions/ML';

import Recomendations from '~/pages/administrators/Recomendations';
import RecomendationsForm from '~/pages/administrators/Recomendations/Form';

import ManageStudents from '~/pages/administrators/ManageStudents';
import StagesEvaluations from '~/pages/administrators/StagesEvaluations';
import Students from '~/pages/administrators/Students';
import StudentInfo from '~/pages/administrators/Students/Info';
import Users from '~/pages/administrators/Users';
import UsersForm from '~/pages/administrators/Users/Form';
import EvaluationForm from '~/pages/administrators/StagesEvaluations/Form';

import LoginStudent from '~/pages/students/LoginStudent';
import Home from '~/pages/students/Home';
import Speck from '~/pages/students/Speck';
import Top10 from '~/pages/students/Top10';
import ExpiredSession from '~/pages/students/ExpiredSession';

import NotFound from '~/pages/NotFound';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Login} />

      <Route
        path="/actions"
        exact
        component={Actions}
        isPrivate
        permission="learnManager"
      />
      <Route
        path="/actions/new"
        component={ActionsForm}
        isPrivate
        permission="learnManager"
      />
      <Route
        path="/actions/:id"
        component={ActionsForm}
        isPrivate
        permission="learnManager"
      />
      <Route path="/ml" component={Ml} isPrivate permission="learnManager" />

      <Route
        path="/recomendations"
        component={Recomendations}
        isPrivate
        exact
        permission="learnManager"
      />
      <Route
        path="/recomendations/new"
        component={RecomendationsForm}
        isPrivate
        exact
        permission="learnManager"
      />
      <Route
        path="/recomendations/:id"
        component={RecomendationsForm}
        isPrivate
        exact
        permission="learnManager"
      />
      <Route
        path="/manage"
        component={ManageStudents}
        isPrivate
        exact
        permission="learnManager"
      />
      <Route
        path="/stages"
        component={StagesEvaluations}
        isPrivate
        exact
        permission="learnManager"
      />
      <Route
        path="/stages/new"
        component={EvaluationForm}
        isPrivate
        exact
        permission="learnManager"
      />
      <Route
        path="/stages/:id"
        component={EvaluationForm}
        isPrivate
        exact
        permission="learnManager"
      />

      <Route
        path="/students"
        exact
        component={Students}
        isPrivate
        permission="specialist"
      />
      <Route
        path="/students/:id"
        component={StudentInfo}
        isPrivate
        permission="specialist"
      />

      <Route
        path="/users"
        component={Users}
        isPrivate
        exact
        permission="administrator"
      />
      <Route
        path="/users/new"
        component={UsersForm}
        isPrivate
        exact
        permission="administrator"
      />
      <Route
        path="/users/:id"
        component={UsersForm}
        isPrivate
        exact
        permission="administrator"
      />

      {/* <Route path="/student/auth/:ra" component={Auth} studentRoute /> */}
      <Route path="/student/auth" exact component={LoginStudent} studentRoute />
      <Route
        path="/student/expired-session"
        component={ExpiredSession}
        studentRoute
      />
      <Route
        path="/student/home"
        exact
        component={Home}
        studentRoute
        isPrivate
      />
      <Route
        path="/student/speck/:id&:id_semester"
        component={Speck}
        studentRoute
        exact
        isPrivate
      />
      <Route
        path="/student/top10/:id&:id_semester"
        component={Top10}
        studentRoute
        exact
        isPrivate
      />

      <Route path="*" component={NotFound} />
    </Switch>
  );
}
