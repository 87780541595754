import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { store } from '~/store';
import Header from '~/components/Header';

function RouteWrapper({
  component: Component,
  isPrivate = false,
  studentRoute,
  permission,
  ...rest
}) {
  const { signed, user } = store.getState().auth;
  const { student_signed } = store.getState().student;

  if (studentRoute && !student_signed && isPrivate) {
    return <Redirect to="/student/auth" />;
  }

  if (studentRoute && !student_signed && !isPrivate) {
    return <Route render={props => <Component {...props} />} {...rest} />;
  }

  if (studentRoute && student_signed) {
    return <Route render={props => <Component {...props} />} {...rest} />;
  }

  if (!signed && isPrivate) {
    return <Redirect to="/" />;
  }

  if (signed && !isPrivate) {
    return <Redirect to="/students" />;
  }

  if (
    (permission === 'learnManager' || permission === 'administrator') &&
    user &&
    user.access_level &&
    user.access_level.id === 3
  ) {
    return <Redirect to="/students" />;
  }

  if (
    (permission === 'specialist' || permission === 'administrator') &&
    user &&
    user.access_level &&
    user.access_level.id === 2
  ) {
    return <Redirect to="/recomendations" />;
  }

  // if (
  //   permission === 'learnManager' &&
  //   user &&
  //   user.access_level &&
  //   user.access_level.id === 4
  // ) {
  //   return <Redirect to="/stages" />;
  // }

  return signed ? (
    <>
      <Header />
      <Route render={props => <Component {...props} />} {...rest} />
    </>
  ) : (
    <Route render={props => <Component {...props} />} {...rest} />
  );
}

RouteWrapper.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.element])
    .isRequired,
  isPrivate: PropTypes.bool,
  studentRoute: PropTypes.bool,
  permission: PropTypes.string,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  studentRoute: false,
  permission: null,
};

export default RouteWrapper;
