import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

export default function Button({ icon, children, ...rest }) {
  return (
    <Container {...rest}>
      {children}
      {icon}
    </Container>
  );
}

Button.propTypes = {
  icon: PropTypes.element,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.func,
    PropTypes.element,
  ]).isRequired,
};

Button.defaultProps = {
  icon: null,
};
