import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

import emptyImage from '~/assets/no-results.svg';

export default function EmptyContainer({ text }) {
  return (
    <Container>
      <img src={emptyImage} alt="Sem resultados" />
      <p>{text}</p>
    </Container>
  );
}

EmptyContainer.propTypes = {
  text: PropTypes.string,
};

EmptyContainer.defaultProps = {
  text: 'Nenhum resultado encontrado',
};
