import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PageHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  padding: 8px 32px;

  position: sticky;
  top: 0;
  background: #fff;
  z-index: 10;

  .images {
    display: flex;
    cursor: pointer;

    img {
      height: 60px;
      margin-right: 24px;
    }
  }

  ul {
    display: flex;
    height: 100%;

    a {
      color: #424242;
      font-size: 16px;
      position: relative;
      cursor: pointer;
      height: 100%;
      transition: color 0.2s;

      li {
        position: relative;
      }

      & + a {
        margin-left: 18px;
        padding-left: 18px;
      }

      & + a::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 1px;
        background: #dedede66;
      }

      &:hover {
        color: #0074a9;
      }

      &.active {
        color: #000;
        font-weight: bold;

        svg {
          color: #0074a9;
        }
      }

      &.active li::after {
        content: '';
        background: #0074a9;
        height: 1px;
        position: absolute;
        bottom: -4px;
        left: 0px;
        right: 0px;
      }
    }
  }

  @media (max-width: 920px) {
    & {
      justify-content: center;

      img {
        display: none;
      }
    }
  }

  @media (max-width: 600px) {
    & {
      ul {
        flex: 1;
        flex-direction: column;

        a {
          & + a {
            margin-left: 0;
            padding-left: 0;
            margin-top: 12px;
          }

          & li.active::after {
            left: 0px;
            right: 0px;
          }
        }
      }
    }
  }
`;

export const ProfileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 64px;

  .profile {
    display: flex;
    flex-direction: column;

    span {
      color: #333;
    }

    p {
      color: #555;
      font-size: 12px;
    }
  }

  button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #999;
    transition: color 0.2s;

    &:hover {
      color: #666;
    }
  }
`;
