import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100%;
  margin-top: 65px;

  img {
    width: 500px;
  }

  p {
    font-size: 24px;
    color: #ddd;
    text-align: center;
  }
`;
