import styled from 'styled-components';
import Select from 'react-select';
import { darken } from 'polished';

export const FormStyle = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  label {
    font-weight: bold;
    margin-bottom: 20px;
  }

  .button-div {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    margin-top: 30px;

    button {
      width: 200px;
      background: #0074a9;
      padding: 10px 30px;
      border-radius: 5px;
      color: white;
      margin-bottom: 30px;
      font-weight: bold;
      text-decoration: none;

      &:hover {
        background: ${darken(0.06, '#0074a9')};
      }
    }
  }
`;

export const Container = styled.div`
  margin: 24px 256px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) {
    margin: 0;
  }
`;

export const ContainerForm = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  span {
    font-weight: 400;
    font-size: 20px;
  }

  .input-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #27a1ca;

    input {
      width: 60px;
      border: none;
    }
  }

  .div-switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 18px;
    margin: 0px;
  }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 24px;
    width: 24px;
    bottom: -2.5px;
    border: 1px solid #27a1ca;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #27a1ca;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #27a1ca;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  .slider.round {
    border-radius: 34px;
  }
  .slider.round:before {
    border-radius: 50%;
  }
`;

export const SelectStyles = styled(Select)`
  width: 120px;
  margin-right: 10px;
  border-radius: 8px;
  color: #0074a9;
`;
