import axios from 'axios';
import { toast } from 'react-toastify';

import { singOut } from '~/store/modules/auth/actions';

const api = axios.create({
  baseURL: process.env.REACT_APP_BACK_URL,
});

export function handleExpireSession(store) {
  api.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 401) {
        store.dispatch(singOut());
        toast.warn('Sua sessão expirou');
      } else {
        throw error;
      }
    }
  );
}

export default api;
