import styled from 'styled-components';

const SectionHead = styled.div`
  position: sticky;
  top: 0;
  background: #0074a9;
  padding: 24px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 15;

  h1 {
    font-weight: normal;
  }

  .filter-options {
    display: flex;
    align-items: center;

    .search-bar {
      margin-right: 10px;
      min-width: 200px;
      max-width: 300px;
      border: 1px solid #fff;
      border-radius: 8px;
      padding: 8px 28px 8px 12px;
      position: relative;

      input {
        display: flex;
        flex: 1;
        background: none;
        border: none;
        color: #fff;
      }

      input::placeholder {
        color: #ccc;
      }

      svg {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto 6px;
        cursor: pointer;
      }
    }
  }

  @media (max-width: 600px) {
    & {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .filter-options {
        margin-top: 8px;
        flex-direction: column;

        .search-bar {
          margin-top: 8px;
        }
      }
    }
  }
`;

export default SectionHead;
