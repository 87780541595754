import styled from 'styled-components';
import { darken } from 'polished';

import Button from '~/components/Button';
import List from '~/components/List';

export const Container = styled.div`
  margin: 24px 64px;
`;

export const AddButton = styled(Button)`
  background: #fff;
  color: #0074a9;
  margin-right: 16px;

  input {
    display: none;
  }

  &:hover {
    background: ${darken(0.06, '#fff')};
  }

  @media (max-width: 600px) {
    margin: 0;
  }
`;

export const ActionsList = styled(List)`
  li {
    min-width: 250px;

    .profile-badge {
      width: 50px;
      height: 100%;
      background: #0074a9;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-weight: bold;
    }

    .info {
      margin-left: 28px;
      padding: 8px 0px;

      h1 {
        font-size: 14px;
        font-weight: normal;
      }

      p {
        font-size: 13px;
        color: #424242;
        margin-top: 12px;
      }

      span {
        margin-top: 8px;
        font-size: 11px;
      }
    }
  }

  @media (max-width: 600px) {
    & {
      li {
        .info {
          margin-left: 8px;
        }
      }
    }
  }
`;
