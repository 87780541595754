/* eslint-disable no-return-assign */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { MdHome, MdExitToApp } from 'react-icons/md';

import { useDispatch } from 'react-redux';
import { singOut } from '~/store/modules/student/actions';

import { Container, PageHeader } from '../styles';
import { ButtonStyle } from './styles';

import logoColegioBatista from '~/assets/logo-cb.png';
// import logoGestaoInovacoes from '~/assets/logo-gi.jpeg';

import history from '~/services/history';

export default function Header() {
  const dispatch = useDispatch();

  function handleLogout() {
    dispatch(singOut());
  }

  return (
    <Container>
      <PageHeader>
        <div className="images" onClick={() => history.push('/student/home')}>
          <img src={logoColegioBatista} alt="Colégio Batista" />
          {/* <img src={logoGestaoInovacoes} alt="Gestão e Inovações" /> */}
        </div>

        <ul>
          <NavLink activeClassName="active" to="/student/home">
            <MdHome size={20} />
          </NavLink>

          <ButtonStyle type="button" onClick={handleLogout}>
            <MdExitToApp size={18} color="#0074a9" />
            Sair
          </ButtonStyle>
        </ul>
      </PageHeader>
    </Container>
  );
}
