/* eslint-disable guard-for-in */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, useRef } from 'react';

import { MdEdit, MdHighlightOff, MdDelete } from 'react-icons/md';
import { format, parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import pt from 'date-fns/locale/pt';
import { useSelector } from 'react-redux';

import { useForm } from 'react-hook-form';
import SectionHead from '~/components/SectionHead';
import {
  ContainerSelection,
  Container,
  Table,
  ActionForm,
  ClearButton,
  Tr,
  Td,
} from './styles';
import Loader from '~/components/Loader';
import EmptyContainer from '~/components/EmptyContainer';
import Pagination from '~/components/Pagination';
import Modal from '~/components/Modal';

import history from '~/services/history';
import api from '~/services/api';

export default function StagesEvaluations() {
  const startDateEL = useRef(null);
  const endDateEL = useRef(null);
  const unitEl = useRef(null);
  const statusEl = useRef(null);
  const semesterEl = useRef(null);

  const [modalIsOpen, setIsOpen] = useState(false);
  const { register, handleSubmit } = useForm();

  const [disableStageId, setDisableStageId] = useState();
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [verMais, setVerMais] = useState([]);

  const [evaluationsStage, setEvaluationsStage] = useState([]);
  const [searchStage, setsearchStage] = useState([]);

  const [units, setUnits] = useState([]);
  const [status, setStatus] = useState([]);
  const [semesters, setSemester] = useState([]);
  const [filter, setFilter] = useState('');

  const userUnit = useSelector(state => state.auth.user.id_unit);

  const access_level_id = useSelector(state =>
    state.auth && state.auth.user && state.auth.user.access_level
      ? state.auth.user.access_level.id
      : null
  );

  let param = '';
  if (access_level_id === 3 || access_level_id === 4) {
    param = userUnit;
  }

  useEffect(() => {
    setLoading(true);
    try {
      api.get(`/evaluation-stage/all/${param}`).then(response => {
        const { data } = response;

        const unitsResult = data.map(item => item.unit);

        const unitsName = unitsResult.filter((elem, index, self) => {
          return index === self.indexOf(elem);
        });

        const unitSort = unitsName.sort((a, b) => {
          if (a > b) {
            return 1;
          }
          if (a < b) {
            return -1;
          }
          return 0;
        });

        const statusResult = data.map(item => item.status);

        const statusName = statusResult.filter((elem, index, self) => {
          return index === self.indexOf(elem);
        });

        const statusSort = statusName.sort((a, b) => {
          if (a > b) {
            return 1;
          }
          if (a < b) {
            return -1;
          }
          return 0;
        });

        const semesterResult = data.map(item => item.semester);

        const semesterDesc = semesterResult.filter((elem, index, self) => {
          return index === self.indexOf(elem);
        });

        const semesterSort = semesterDesc.sort((a, b) => {
          if (a > b) {
            return 1;
          }
          if (a < b) {
            return -1;
          }
          return 0;
        });

        const dataResult = data.map(item => {
          const start_dateFormat = format(
            parseISO(item.start_date),
            'yyyy-MM-dd'
          );
          const startDate = format(
            parseISO(item.start_date),
            "dd'/'MM'/'yyyy",
            {
              locale: pt,
            }
          );
          const end_dateFormat = format(parseISO(item.end_date), 'yyyy-MM-dd');
          const endDate = format(parseISO(item.end_date), "dd'/'MM'/'yyyy", {
            locale: pt,
          });

          return {
            id: item.id,
            title: item.title,
            description: item.description,
            unit: item.unit,
            status: item.status,
            start_date: startDate,
            end_date: endDate,
            semester: item.semester,
            startDateFormat: start_dateFormat,
            endDateFormat: end_dateFormat,
          };
        });

        setUnits(unitSort);
        setStatus(statusSort);
        setSemester(semesterSort);
        setEvaluationsStage(dataResult);
        setLoading(false);
      });
    } catch (error) {
      toast.error('Falha ao buscar dados das etapas');
      setLoading(false);
    }
  }, [param, userUnit]);

  useEffect(() => {
    setCurrentPage(1);
    setsearchStage(
      evaluationsStage.filter(item => {
        for (const key in filter) {
          if (item[key] === undefined || item[key] !== filter[key])
            return false;
        }
        return true;
      })
    );
  }, [evaluationsStage, filter]);

  const toggl = stage => {
    setVerMais(item =>
      verMais.includes(stage.id)
        ? item.filter(id => stage.id !== id)
        : [...item, stage.id]
    );
  };

  const onSubmit = async data => {
    setIsOpen(false);
    setLoading(true);
    const { description } = data;
    try {
      await api.put(`/evaluation-stage/status/${disableStageId}`, {
        description,
      });
      toast.success('Alterações salvas com sucesso!');

      setEvaluationsStage(
        evaluationsStage.map(item => {
          if (item.id === disableStageId) {
            item.status = 'Desativada';
          }
          return item;
        })
      );

      setLoading(false);
    } catch (error) {
      setIsOpen(false);
      toast.error('Erro ao salvar alteração!');
    }
  };

  const handleClear = () => {
    startDateEL.current.value = '';
    endDateEL.current.value = '';
    unitEl.current.value = '';
    statusEl.current.value = '';
    semesterEl.current.value = '';
  };

  const pages = Math.ceil(searchStage.length / perPage);
  const indexOfLastPost = currentPage * perPage;
  const indexOfFirstPost = indexOfLastPost - perPage;
  const currentStages = searchStage.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <>
      <SectionHead>
        <h1>Avaliações</h1>

        <ContainerSelection>
          <span>Data inicial </span>

          <input
            ref={startDateEL}
            type="date"
            onChange={e =>
              setFilter({ ...filter, startDateFormat: e.target.value })
            }
          />

          <span>Data final </span>

          <input
            ref={endDateEL}
            type="date"
            onChange={e =>
              setFilter({ ...filter, endDateFormat: e.target.value })
            }
          />

          <select
            ref={unitEl}
            onChange={e => setFilter({ ...filter, unit: e.target.value })}
          >
            <option value="" selected disabled hidden>
              Unidade
            </option>

            {units.map(unit => (
              <option value={unit} key={unit}>
                {unit}
              </option>
            ))}
          </select>

          <select
            ref={semesterEl}
            onChange={e => setFilter({ ...filter, semester: e.target.value })}
          >
            <option value="" selected disabled hidden>
              Semestre
            </option>

            {semesters.map(semester => (
              <option value={semester} key={semester}>
                {semester}
              </option>
            ))}
          </select>

          <select
            ref={statusEl}
            onChange={e => setFilter({ ...filter, status: e.target.value })}
          >
            <option value="" selected disabled hidden>
              Status
            </option>

            {status.map(unit => (
              <option value={unit} key={unit}>
                {unit}
              </option>
            ))}
          </select>
          <ClearButton
            onClick={() => {
              setFilter('');
              handleClear();
            }}
          >
            Limpar Filtros
          </ClearButton>
        </ContainerSelection>
      </SectionHead>

      <Container>
        <div>
          <button
            className="newEvaluation"
            type="button"
            onClick={() => history.push('/stages/new')}
          >
            Criar nova fase de avaliação
          </button>
        </div>

        {currentStages.length > 0 ? (
          <>
            <Table>
              <thead>
                <tr>
                  <th>Título</th>
                  <th>Descrição</th>
                  <th>Unidade</th>
                  <th>Data inicial</th>
                  <th>Data final</th>
                  <th>Semestre</th>
                  <th>Status da etapa</th>
                  <th className="alignCenter">Editar</th>
                  <th className="alignCenter">Desativar etapa</th>
                </tr>
              </thead>
              <tbody>
                {currentStages.map(stage => (
                  <React.Fragment key={stage.id}>
                    <Tr status={stage.status}>
                      <td>{stage.title}</td>
                      <td>
                        <button
                          className="verMais"
                          type="button"
                          onClick={() => toggl(stage)}
                        >
                          {verMais.includes(stage.id) ? 'Ocultar' : 'Ver mais'}
                        </button>
                      </td>
                      <td>{stage.unit}</td>
                      <td>{stage.start_date}</td>
                      <td>{stage.end_date}</td>
                      <td>{stage.semester}</td>
                      <Td className="tdStatus" status={stage.status}>
                        {stage.status}
                      </Td>
                      <td className="alignCenter">
                        {stage.status === 'Em andamento' ||
                        stage.status === 'A ser realizada' ? (
                          <button
                            type="button"
                            onClick={() => history.push(`/stages/${stage.id}`)}
                          >
                            <MdEdit size={20} color="#4185f4" />
                          </button>
                        ) : (
                          <MdEdit size={20} color="#D3D3D3" />
                        )}
                      </td>
                      <td className="alignCenter">
                        {stage.status === 'Em andamento' ||
                        stage.status === 'A ser realizada' ? (
                          <button
                            type="button"
                            onClick={() => {
                              setIsOpen(true);
                              setDisableStageId(stage.id);
                            }}
                          >
                            <MdDelete size={20} color="#4185f4" />
                          </button>
                        ) : (
                          <MdDelete size={20} color="#D3D3D3" />
                        )}
                      </td>
                    </Tr>
                    {verMais.includes(stage.id) && (
                      <tr className="description">
                        <td colSpan={8}>{stage.description}</td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </Table>

            <div className="div-pagination">
              <span>Itens por página:</span>
              <select
                defaultValue={10}
                name="itensPagination"
                onChange={e => {
                  setCurrentPage(1);
                  setPerPage(e.target.value);
                }}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
              </select>
              <Pagination
                pages={pages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </>
        ) : (
          <>
            <EmptyContainer text="Nenhuam etapa encontrada." />
          </>
        )}
      </Container>

      <Modal open={modalIsOpen}>
        <button type="button" onClick={() => setIsOpen(false)}>
          <MdHighlightOff size={30} color="#fff" />
        </button>
        <ActionForm onSubmit={handleSubmit(onSubmit)}>
          <div>
            <span>Motivo da desativação:</span>
            <input name="description" ref={register} autoFocus required />
          </div>
          <button type="submit">Confirmar</button>
        </ActionForm>
      </Modal>

      <Loader visible={loading} />
    </>
  );
}
