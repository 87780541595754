import React from 'react';

import { Container } from './styles';

import errorImage from '~/assets/error-401.png';

export default function ExpiredSession() {
  return (
    <Container>
      <img src={errorImage} alt="Sessão expirada" />
      <p>Acesse o portal do aluno para ter acesso novamente ao sistema...</p>
    </Container>
  );
}
