import React, { useState, useEffect, useCallback } from 'react';
import { MdSearch, MdEdit, MdFileDownload } from 'react-icons/md';
import { toast } from 'react-toastify';
import { defer } from 'rxjs';
import { useSelector } from 'react-redux';
import { Container, StudentsList, ImportButton } from './styles';

import Pagination from '~/components/Pagination';
import SectionHead from '~/components/SectionHead';
import Loader from '~/components/Loader';
import EmptyContainer from '~/components/EmptyContainer';
import ConfirmDialog from '~/components/ConfirmDialog';
import CsvDialog from './CsvDialog';

import api from '~/services/api';
import history from '~/services/history';

export default function Students() {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);

  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [filter, setFilter] = useState('');

  const userUnit = useSelector(state => state.auth.user.id_unit);

  const access_level_id = useSelector(state =>
    state.auth && state.auth.user && state.auth.user.access_level
      ? state.auth.user.access_level.id
      : null
  );

  let param = '';
  if (access_level_id === 3 || access_level_id === 4) {
    param = userUnit;
  }

  const loadStudents = useCallback(() => {
    function fetch() {
      return api.get('/students', {
        params: {
          page: currentPage,
          ra: filter,
          className: filter,
          classSerie: filter,
          id_unit: param,
        },
      });
    }

    setLoading(true);

    const subscription = defer(fetch).subscribe(async response => {
      try {
        setPages(Number(response.headers.total_pages));
        setStudents(response.data);
      } catch (error) {
        toast.error('Erro ao listar alunos');
      } finally {
        setLoading(false);
      }
    });

    return subscription;
  }, [currentPage, filter, param]);

  useEffect(() => {
    const subscription = loadStudents();
    return () => subscription.unsubscribe();
  }, [loadStudents]);

  function handleOpenDownloadDialog() {
    ConfirmDialog({
      component: onClose => <CsvDialog onClose={onClose} filter={filter} />,
    });
  }

  return (
    <>
      <SectionHead>
        <h1>ALUNOS</h1>
        <div className="filter-options">
          {(access_level_id === 1 || access_level_id === 2) && (
            <ImportButton
              onClick={() => handleOpenDownloadDialog()}
              icon={<MdFileDownload id="csv-download" color="#0074a9" />}
            >
              Exportar dados CSV
            </ImportButton>
          )}

          <div className="search-bar">
            <form>
              <input
                type="text"
                placeholder="Turma ou RA"
                value={filter}
                onChange={e => {
                  setFilter(e.target.value);
                  setCurrentPage(1);
                }}
              />
            </form>
            <MdSearch size="20" />
          </div>
        </div>
      </SectionHead>
      <Container>
        {students.length > 0 ? (
          <>
            <StudentsList>
              {students.map(student => (
                <li key={student.id}>
                  <div className="profile-badge">
                    <span>RA: {student.ra}</span>
                  </div>
                  <div className="info">
                    <p>{student.class.name}</p>
                    <h1>{student.class.serie}</h1>
                  </div>
                  <div className="options">
                    <button
                      type="button"
                      onClick={() => history.push(`/students/${student.id}`)}
                    >
                      <MdEdit size={20} color="#4185f4" />
                    </button>
                    {/* <MdDelete size={20} color="#ea443b" /> */}
                  </div>
                </li>
              ))}
            </StudentsList>
          </>
        ) : (
          <EmptyContainer text="Nenhum aluno encontrado" />
        )}
        <Pagination
          pages={pages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Container>
      <Loader visible={loading} />
    </>
  );
}
