import React, { useState, useEffect, useCallback } from 'react';
import { MdSearch, MdAdd, MdEdit, MdDelete, MdArrowBack } from 'react-icons/md';
import { toast } from 'react-toastify';

import { Container, AddButton, RecomendationsList } from './styles';

import SectionHead from '~/components/SectionHead';
import Pagination from '~/components/Pagination';
import ConfirmDialog from '~/components/ConfirmDialog';
import Loader from '~/components/Loader';
import EmptyContainer from '~/components/EmptyContainer';

import history from '~/services/history';
import api from '~/services/api';

export default function Recomendations() {
  const [recomendations, setRecomendations] = useState([]);
  const [loading, setLoading] = useState(false);

  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [filter, setFilter] = useState('');

  const loadRecomendations = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/recomendations', {
        params: { page: currentPage, name: filter },
      });
      setPages(Number(response.headers.total_pages));
      setRecomendations(response.data);
    } catch (error) {
      toast.error('Erro ao listar recomendações');
    } finally {
      setLoading(false);
    }
  }, [currentPage, filter]);

  useEffect(() => {
    loadRecomendations();
  }, [loadRecomendations]);

  async function handleDelete(recomendation) {
    async function deleteRecomendation() {
      try {
        setLoading(true);
        await api.delete(`/recomendations/${recomendation.id}`);
        toast.success('Recomendação excluida');
      } catch (error) {
        toast.error('Erro ao deletar recomendação');
      } finally {
        await loadRecomendations();
        setLoading(false);
      }
    }

    ConfirmDialog({
      title: 'Apagar matrícula',
      onConfirm: deleteRecomendation,
      content: <p>Tem certeza que deseja apagar a recomendação?</p>,
    });
  }

  return (
    <>
      <SectionHead>
        <h1>RECOMENDAÇÕES</h1>
        <div className="filter-options">
          <AddButton
            icon={<MdAdd />}
            onClick={() => history.push('/recomendations/new')}
          >
            Adicionar recomendação
          </AddButton>

          <div className="search-bar">
            <form>
              <input
                type="text"
                placeholder="nome da recomendação"
                value={filter}
                onChange={e => setFilter(e.target.value)}
              />
            </form>
            <MdSearch size="20" />
          </div>

          <MdArrowBack
            size={20}
            style={{ cursor: 'pointer' }}
            onClick={() => history.goBack()}
          />
        </div>
      </SectionHead>
      <Container>
        {recomendations.length > 0 ? (
          <>
            <RecomendationsList>
              {recomendations.map(recomendation => (
                <li key={recomendation.id}>
                  <div className="info">
                    <h1>{recomendation.name}</h1>
                    <p>{recomendation.description}</p>
                  </div>
                  <div className="options">
                    <button
                      type="button"
                      onClick={() =>
                        history.push(`/recomendations/${recomendation.id}`)
                      }
                    >
                      <MdEdit size={20} color="#4185f4" />
                    </button>
                    <MdDelete
                      size={20}
                      color="#ea443b"
                      onClick={() => handleDelete(recomendation)}
                    />
                  </div>
                </li>
              ))}
            </RecomendationsList>
            <Pagination
              pages={pages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </>
        ) : (
          <EmptyContainer text="Nenhuma recomendação encontrada" />
        )}
      </Container>
      <Loader visible={loading} />
    </>
  );
}
