import React, { useState, useEffect, useCallback } from 'react';
import { MdArrowBack } from 'react-icons/md';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { Container, ActionForm, AsyncInput, SubmitButton } from './styles';

import SectionHead from '~/components/SectionHead';
import Input from '~/components/Input';
import Loader from '~/components/Loader';

import api from '~/services/api';
import history from '~/services/history';

export default function Form({ match }) {
  const [action, setAction] = useState({});
  const [loading, setLoading] = useState(false);

  const [selectedRecomendation, setSelectedRecomendation] = useState(null);
  const [
    defaultRecomendationOptions,
    setDefaultRecomendationOptions,
  ] = useState([]);

  const { id } = match.params;

  const loadRecomendations = useCallback(async (inputValue = '') => {
    try {
      const response = await api.get('/recomendations', {
        params: { name: inputValue },
      });

      const data = response.data.map(actionResponse => ({
        id: actionResponse.id,
        title: actionResponse.name,
        data: actionResponse,
      }));

      return data;
    } catch (error) {
      return toast.error('Erro ao listar recomendações');
    }
  }, []);

  useEffect(() => {
    async function loadDefaultRecomendationOptions() {
      try {
        const response = await api.get('/recomendations');
        const data = response.data.map(recomendation => ({
          id: recomendation.id,
          title: recomendation.name,
          data: recomendation,
        }));
        return setDefaultRecomendationOptions(data);
      } catch (error) {
        return toast.error('Erro ao listar recomendações');
      }
    }
    loadDefaultRecomendationOptions();
  }, []);

  useEffect(() => {
    async function loadAction() {
      if (id) {
        try {
          setLoading(true);
          const response = await api.get(`/actions/${id}`);
          setAction(response.data);

          setSelectedRecomendation({
            id: response.data.recomendation.id,
            title: response.data.recomendation.name,
            data: response.data.recomendation,
          });
        } catch (error) {
          toast.error('Erro ao buscar dados da ação');
        } finally {
          setLoading(false);
        }
      }
    }
    loadAction();
  }, [id]);

  async function handleSubmit() {
    if (!action.name || !selectedRecomendation) {
      return toast.warn('Preencha o formulário corretamente');
    }

    if (id) {
      try {
        setLoading(true);
        await api.put(`/actions/${id}`, {
          ...action,
          id_recomendation: selectedRecomendation.id,
        });
        toast.success('Ação atualizada');
        history.push('/actions');
      } catch (error) {
        toast.error('Erro ao atualizar dados da ação');
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        await api.post('/actions', {
          ...action,
          id_recomendation: selectedRecomendation.id,
        });
        toast.success('Ação criada com sucesso');
        history.push('/actions');
      } catch (error) {
        toast.error('Erro ao criar ação');
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <>
      <SectionHead>
        <h1>{id ? action.name : 'CRIAR AÇÃO'}</h1>
        <MdArrowBack
          size={20}
          style={{ cursor: 'pointer' }}
          onClick={() => history.push('/actions')}
        />
      </SectionHead>
      <Container>
        <ActionForm>
          <Input
            label="NOME AÇÃO"
            name="name"
            value={action.name || ''}
            placeholder="nome da ação"
            onChange={e => setAction({ ...action, name: e.target.value })}
            required
          />
          <Input
            label="DESCRIÇÃO"
            name="description"
            value={action.description || ''}
            placeholder="descrição da ação"
            onChange={e =>
              setAction({
                ...action,
                description: e.target.value,
              })
            }
          />
          <label>RECOMENDAÇÃO</label>
          <AsyncInput
            name="recomendation"
            aria-label="id_recomendation"
            placeholder="recomendação associada"
            loadOptions={loadRecomendations}
            value={selectedRecomendation}
            onChange={updatedValue => setSelectedRecomendation(updatedValue)}
            defaultOptions={defaultRecomendationOptions}
            isMulti={false}
            cacheOptions
            getOptionValue={option => option.id}
            getOptionLabel={option => option.title}
            noOptionsMessage={() => 'Nenhuma ação encontrada'}
            required
          />
        </ActionForm>

        <SubmitButton type="submit" onClick={handleSubmit}>
          {id ? 'ATUALIZAR' : 'CRIAR'}
        </SubmitButton>
      </Container>
      <Loader visible={loading} />
    </>
  );
}

Form.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

Form.defaultProps = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: null,
    }),
  }),
};
