import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import produce from 'immer';

import { Container, AnswerContainer } from './styles';

import { updateAnswers } from '~/store/modules/student/actions';

export default function Question({ data }) {
  const [isChecked, setIsChecked] = useState(false);

  const answers = useSelector(state => state.student.answers);
  const disptach = useDispatch();

  return (
    <Container isChecked={isChecked}>
      <h1>{data.question}</h1>
      {data.answers.map(answer => (
        <AnswerContainer key={answer.id}>
          <input
            id={answer.id}
            type="radio"
            name={data.id}
            onChange={() => {
              setIsChecked(true);
              const newAnswerState = produce(answers, draftState => {
                const questionIndex = draftState.findIndex(
                  item => item.question === data.question
                );

                if (questionIndex >= 0) {
                  draftState[questionIndex].answer = answer.answer;
                  draftState[questionIndex].answer_id = answer.id;
                } else {
                  draftState.push({
                    question: data.question,
                    answer: answer.answer,
                    answer_id: answer.id,
                  });
                }
              });
              disptach(updateAnswers(newAnswerState));
            }}
            value={answer.answer}
            onInvalid={e => {
              e.preventDefault();
            }}
            checked={
              answers
                .map(item => item.answer_id)
                .filter((item, index, array) => {
                  if (array.includes(answer.id)) {
                    if (!isChecked) setIsChecked(true);
                    return true;
                  }
                  return false;
                }).length > 0
            }
            required
          />
          <label htmlFor={answer.id}>{answer.answer}</label>
        </AnswerContainer>
      ))}
    </Container>
  );
}

Question.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    question: PropTypes.string,
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        answer: PropTypes.string,
      })
    ),
  }).isRequired,
};
