import styled from 'styled-components';
import AsyncSelect from 'react-select/async';

import { darken } from 'polished';

import Button from '~/components/Button';

export const Container = styled.div`
  margin: 24px 64px;

  label {
    color: #444444;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
  }
`;

export const ActionForm = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    margin-bottom: 26px;
  }
`;

export const AsyncInput = styled(AsyncSelect)`
  & > div {
    padding-left: 5px;
  }
`;

export const SubmitButton = styled(Button)`
  background: #fff;
  color: #183757;
  float: right;
  margin: 16px;
  position: fixed;
  height: 60px;
  border-radius: 40px;
  bottom: 0;
  right: 0;
  box-shadow: 1px 1px 4px 1px #bbb;
  font-weight: bold;
  font-size: 16px;
  transition: background 0.2s;

  &:hover {
    background: ${darken(0.04, '#fff')};
  }
`;
