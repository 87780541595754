/* eslint-disable guard-for-in */
/* eslint-disable no-console */
/* eslint-disable no-restricted-syntax */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useCallback } from 'react';
import { MdSearch } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import {
  Container,
  SelectStyles,
  Button,
  ClearButton,
  ContainerSelection,
  Table,
  Tr,
} from './styles';
import SectionHead from '~/components/SectionHead';
import Pagination from '~/components/Pagination';
import ConfirmDialog from '~/components/ConfirmDialog';
import Loader from '~/components/Loader';
import EmptyContainer from '~/components/EmptyContainer';
import history from '~/services/history';
import api from '~/services/api';

export default function ManageStudents() {
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [filterRa, setFilterRa] = useState('');
  const [filter, setFilter] = useState('');
  const [searchStudents, setsearchStudents] = useState([]);

  const [students, setStudents] = useState([]);
  const [units, setUnits] = useState([]);
  const [series, setSeries] = useState([]);
  const [classes, setClasses] = useState([]);
  const [status, setStatus] = useState([]);
  const [statusSelect, setStatusSelect] = useState('');
  const [studentsCount, setStudentsCount] = useState({});

  const userUnit = useSelector(state => state.auth.user.id_unit);
  const access_level_id = useSelector(state =>
    state.auth && state.auth.user && state.auth.user.access_level
      ? state.auth.user.access_level.id
      : null
  );

  let param = '';
  if (access_level_id === 3 || access_level_id === 4) {
    param = userUnit;
  }

  useEffect(() => {
    setLoading(true);
    api.get(`students/all/${param}`).then(response => {
      const { data } = response;
      const resultStudents = data.map(student => {
        return {
          id: student.id,
          ra: student.ra,
          serie: student.class.serie,
          class: student.class.name,
          unit: student.unit.name,
          statusEvaluation: student.evaluation_status,
          changed: false,
        };
      });

      const total = resultStudents.length;
      const active = resultStudents.filter(student => student.statusEvaluation);
      const dispo = 6000 - active.length;

      setStudentsCount({ total, active: active.length, dispo });

      const unitsResult = resultStudents.map(unit => unit.unit);
      const serieResult = resultStudents.map(serie => serie.serie);
      const classResult = resultStudents.map(className => className.class);

      const unitsName = unitsResult.filter((elem, index, self) => {
        return index === self.indexOf(elem);
      });
      const resulUnitsSelect = unitsName.map(item => {
        return { value: item, label: item };
      });

      const unitSort = resulUnitsSelect.sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        return 0;
      });

      const serieName = serieResult.filter((elem, index, self) => {
        return index === self.indexOf(elem);
      });
      const resulSerieSelect = serieName.map(item => {
        return { value: item, label: item };
      });

      const serieSort = resulSerieSelect.sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        return 0;
      });

      const className = classResult.filter((elem, index, self) => {
        return index === self.indexOf(elem);
      });
      const resulClassSelect = className.map(item => {
        return { value: item, label: item };
      });

      const classSort = resulClassSelect.sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        return 0;
      });

      const evaluation = [
        { value: true, label: 'Ativado' },
        { value: false, label: 'Desativado' },
      ];

      setStudents(resultStudents);
      setUnits(unitSort);
      setSeries(serieSort);
      setClasses(classSort);
      setStatus(evaluation);
      setLoading(false);
    });
  }, [param]);

  useEffect(() => {
    setCurrentPage(1);
    setsearchStudents(
      students.filter(item => {
        if (filterRa) return item.ra.includes(filterRa);

        for (const key in filter) {
          if (item[key] === undefined || item[key] !== filter[key])
            return false;
        }
        return true;
      })
    );
  }, [filter, filterRa, students]);

  const handleSave = useCallback(async () => {
    const changedStudents = students.filter(student => student.changed);
    const resultStudentInf = changedStudents.map(item => {
      return { ra: item.ra, status: item.statusEvaluation };
    });
    async function saveStudents() {
      setLoading(true);
      try {
        const res = await api.put('students', { resultStudentInf });
        const { data } = res;
        if (data.error) {
          toast.warn(`${data.error}`);
        } else {
          toast.success('Alterações salvas com suscesso!');
        }

        setTimeout(() => {
          history.go();
        }, 2000);
      } catch (error) {
        toast.error('Falha ao salvar as alterações');
      }
      setLoading(false);
    }

    if (resultStudentInf.length > 0) {
      ConfirmDialog({
        title: "Ra's alterados",
        onConfirm: saveStudents,
        content: resultStudentInf.map(item => (
          <p key={item.ra}>
            RA:{item.ra}-{item.status ? 'Ativado' : 'Desativado'}
          </p>
        )),
      });
    } else {
      toast.warn('Nenhum registro foi alterado');
    }
  }, [students]);

  const pages = Math.ceil(searchStudents.length / perPage);
  const indexOfLastPost = currentPage * perPage;
  const indexOfFirstPost = indexOfLastPost - perPage;
  const currentStudents = searchStudents.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  return (
    <>
      <SectionHead>
        <h1>Gerenciar Estudantes</h1>
        <ContainerSelection>
          <SelectStyles
            placeholder={filter.unit || 'Unidade'}
            value={filter.unit || { label: 'Unidade' }}
            options={units}
            onChange={e => setFilter({ ...filter, unit: e.value })}
          />
          <SelectStyles
            placeholder={filter.serie || 'Série'}
            value={filter.serie || { label: 'Série' }}
            options={series}
            onChange={e => setFilter({ ...filter, serie: e.value })}
          />
          <SelectStyles
            placeholder={filter.class || 'Turma'}
            value={filter.class || { label: 'Turma' }}
            options={classes}
            onChange={e => setFilter({ ...filter, class: e.value })}
          />
          <SelectStyles
            placeholder={statusSelect || 'Status'}
            value={statusSelect || { label: 'Status' }}
            options={status}
            onChange={e => {
              setFilter({ ...filter, statusEvaluation: e.value });
              setStatusSelect(e.label);
            }}
          />
        </ContainerSelection>
        <div className="filter-options">
          <div className="search-bar">
            <form>
              <input
                type="text"
                placeholder="Busca por RA"
                value={filterRa}
                onChange={e => setFilterRa(e.target.value)}
              />
            </form>
            <MdSearch size="20" />
          </div>
        </div>
        <ClearButton
          onClick={() => {
            setFilter('');
            setStatusSelect('');
            setFilterRa('');
          }}
        >
          Limpar Filtros
        </ClearButton>
      </SectionHead>

      <Container>
        {currentStudents.length > 0 ? (
          <>
            <div className="activate-all">
              <div className="div-activate-all">
                <span>Ativar/Desativar todos</span>
                <div className="div-switch">
                  <label className="switch">
                    <input
                      type="checkbox"
                      onChange={e => {
                        const { checked } = e.target;
                        setsearchStudents(
                          searchStudents.map(student => {
                            if (student.statusEvaluation !== checked) {
                              student.statusEvaluation = checked;
                              student.changed = !student.changed;
                            }
                            return student;
                          })
                        );
                      }}
                    />
                    <span className="slider round" />
                  </label>
                </div>
              </div>

              <span>Você poderá ativar até 6.000 estudantes. </span>
            </div>

            <Table>
              <thead>
                <tr>
                  <th>Aluno</th>
                  <th>Unidade</th>
                  <th>Série</th>
                  <th>Turma</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {currentStudents.map(student => (
                  <Tr status={student.statusEvaluation} key={student.id}>
                    <td>RA: {student.ra}</td>
                    <td>Unidade - {student.unit} </td>
                    <td>{student.serie}</td>
                    <td>{student.class}</td>
                    <td>
                      <label className="switch">
                        <input
                          id="normal"
                          type="checkbox"
                          checked={student.statusEvaluation}
                          onChange={event => {
                            const { checked } = event.target;
                            setsearchStudents(
                              searchStudents.map(data => {
                                if (student.id === data.id) {
                                  data.statusEvaluation = checked;
                                  data.changed = !data.changed;
                                }
                                return data;
                              })
                            );
                          }}
                        />
                        <span className="slider round" />
                      </label>
                    </td>
                  </Tr>
                ))}
              </tbody>
            </Table>

            <div className="div-pagination">
              <span>Itens por página:</span>
              <select
                defaultValue={10}
                name="itensPagination"
                onChange={e => {
                  setCurrentPage(1);
                  setPerPage(e.target.value);
                }}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
              </select>

              <Pagination
                pages={pages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>

            <div className="footer">
              <>
                <div className="student-count">
                  <div>
                    <span>Total de estudantes:</span>
                    <p> {studentsCount.total}</p>
                  </div>

                  <div>
                    <span>Total ativos:</span>
                    <p> {studentsCount.active}</p>
                  </div>

                  <div>
                    <span>Saldo dísponivel:</span>
                    <p> {studentsCount.dispo}</p>
                  </div>
                </div>

                <Button type="submit" onClick={handleSave}>
                  Salvar
                </Button>
              </>
            </div>
          </>
        ) : (
          <>
            <EmptyContainer text="Nenhum aluno encontrado com esses filtros" />
          </>
        )}
      </Container>

      <Loader visible={loading} />
    </>
  );
}
