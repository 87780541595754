import styled from 'styled-components';
import { darken } from 'polished';

import Button from '~/components/Button';
import List from '~/components/List';

export const Container = styled.div`
  margin: 24px 64px;
`;

export const AddButton = styled(Button)`
  background: #fff;
  color: #0074a9;
  margin-right: 16px;

  input {
    display: none;
  }

  &:hover {
    background: ${darken(0.06, '#fff')};
  }

  @media (max-width: 600px) {
    margin: 0;
  }
`;

export const RecomendationsList = styled(List)`
  li {
    .info {
      margin-left: 28px;
      padding: 8px 0px;
      width: 100%;
      height: 100%;

      h1 {
        font-size: 14px;
        font-weight: normal;
      }

      p {
        font-size: 14px;
        color: #424242;
        margin-top: 6px;
        margin-right: 16px;
        text-align: justify;
      }
    }
  }
`;
