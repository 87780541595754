import React from 'react';
import { NavLink } from 'react-router-dom';
import { MdExitToApp } from 'react-icons/md';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import { useDispatch, useSelector } from 'react-redux';

import { Container, PageHeader, ProfileHeader } from './styles';

import logoColegioBatista from '~/assets/logo-cb.png';
// import logoGestaoInovacoes from '~/assets/logo-gi.jpeg';

import { singOut } from '~/store/modules/auth/actions';

export default function Header() {
  const todayDate = format(new Date(), "dd 'de' MMMM 'de' yyyy", {
    locale: pt,
  });

  const username = useSelector(state =>
    state.auth && state.auth.user ? state.auth.user.name : ''
  );

  const access_level_id = useSelector(state =>
    state.auth && state.auth.user && state.auth.user.access_level
      ? state.auth.user.access_level.id
      : null
  );

  const dispatch = useDispatch();

  function handleLogout() {
    dispatch(singOut());
  }

  return (
    <>
      <Container>
        <PageHeader>
          <div className="images">
            <img src={logoColegioBatista} alt="Colégio Batista" />
            {/* <img src={logoGestaoInovacoes} alt="Gestão e Inovações" /> */}
          </div>

          <ul>
            {(access_level_id === 1 ||
              access_level_id === 3 ||
              access_level_id === 4) && (
              <NavLink activeClassName="active" to="/students">
                <li>Alunos</li>
              </NavLink>
            )}

            {(access_level_id === 1 ||
              access_level_id === 2 ||
              access_level_id === 4) && (
              <NavLink activeClassName="active" to="/manage">
                <li>Gerenciar Estudantes</li>
              </NavLink>
            )}

            {(access_level_id === 1 ||
              access_level_id === 2 ||
              access_level_id === 4) && (
              <NavLink activeClassName="active" to="/stages">
                <li>Avaliações</li>
              </NavLink>
            )}

            {/* {(access_level_id === 1 ||
              access_level_id === 2 ||
              access_level_id === 4) && (
              <NavLink activeClassName="active" to="/recomendations">
                <li>Recomendações</li>
              </NavLink>
            )} */}

            {(access_level_id === 1 ||
              access_level_id === 2 ||
              access_level_id === 4) && (
              <NavLink activeClassName="active" to="/actions">
                <li>Plano de ações</li>
              </NavLink>
            )}

            {access_level_id === 1 && (
              <NavLink activeClassName="active" to="/users">
                <li>Usuários</li>
              </NavLink>
            )}
          </ul>
        </PageHeader>
        <ProfileHeader>
          <div className="profile">
            <span>Bem vindo {username}</span>
            <p>{todayDate}</p>
          </div>

          <button type="button" onClick={handleLogout}>
            <MdExitToApp />
            Sair
          </button>
        </ProfileHeader>
      </Container>
    </>
  );
}
