import React, { useState, useEffect, useCallback } from 'react';
import { MdAdd, MdSearch, MdEdit, MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';

import { Container, ActionsList, AddButton } from './styles';

import SectionHead from '~/components/SectionHead';
import Pagination from '~/components/Pagination';
import ConfirmDialog from '~/components/ConfirmDialog';
import Loader from '~/components/Loader';
import EmptyContainer from '~/components/EmptyContainer';

import api from '~/services/api';
import history from '~/services/history';

export default function Actions() {
  const [actions, setActions] = useState([]);

  const [loading, setLoading] = useState(false);

  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [filter, setFilter] = useState('');

  const loadActions = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/actions', {
        params: { page: currentPage, name: filter },
      });
      setPages(Number(response.headers.total_pages));
      setActions(response.data);
    } catch (error) {
      toast.error('Erro ao listar ações');
    } finally {
      setLoading(false);
    }
  }, [currentPage, filter]);

  useEffect(() => {
    loadActions();
  }, [loadActions]);

  async function handleDelete(action) {
    async function deleteAction() {
      try {
        setLoading(true);
        await api.delete(`/actions/${action.id}`);
        toast.success('Ação excluida');
      } catch (error) {
        toast.error('Erro ao deletar ação');
      } finally {
        await loadActions();
        setLoading(false);
      }
    }

    ConfirmDialog({
      title: 'Apagar ação',
      onConfirm: deleteAction,
      content: <p>Tem certeza que deseja apagar a ação?</p>,
    });
  }

  return (
    <>
      <SectionHead>
        <h1>AÇÕES</h1>
        <div className="filter-options">
          <AddButton onClick={() => history.push('/recomendations')}>
            Recomendações
          </AddButton>

          {/* <AddButton onClick={() => history.push('/Ml')}>
            Machine Learning
          </AddButton> */}

          <AddButton
            icon={<MdAdd />}
            onClick={() => history.push('/actions/new')}
          >
            Adicionar ação
          </AddButton>

          <div className="search-bar">
            <form>
              <input
                type="text"
                placeholder="nome da ação"
                value={filter}
                onChange={e => setFilter(e.target.value)}
              />
            </form>
            <MdSearch size="20" />
          </div>
        </div>
      </SectionHead>
      <Container>
        {actions.length > 0 ? (
          <>
            <ActionsList>
              {actions.map(action => (
                <li key={action.id}>
                  <div className="info">
                    <h1>{action.name}</h1>
                    <p>{action.description}</p>
                    <span>Recomendação: {action.recomendation.name}</span>
                  </div>
                  <div className="options">
                    <button
                      type="button"
                      onClick={() => history.push(`/actions/${action.id}`)}
                    >
                      <MdEdit size={20} color="#4185f4" />
                    </button>
                    <MdDelete
                      size={20}
                      color="#ea443b"
                      onClick={() => handleDelete(action)}
                    />
                  </div>
                </li>
              ))}
            </ActionsList>
            <Pagination
              pages={pages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </>
        ) : (
          <EmptyContainer text="Nenhuma ação encontrada" />
        )}
      </Container>
      <Loader visible={loading} />
    </>
  );
}
