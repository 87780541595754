import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;

  p {
    color: #ddd;
    font-size: 2em;
  }

  img {
    width: 320px;
  }
`;
